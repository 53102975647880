import { axios } from "../../../lib/axios";
import { API_ROUTES } from "../../../utils/api_routes";

export const uploadImage=(file:any)=>{
	const formData = new FormData();
	
	formData.append("file", file)
	

	return axios.post(API_ROUTES.COMMON.UPLOAD_FILE, formData, {headers:{"Content-Type":"multipart/form-data"}})
}