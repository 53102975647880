export const COUPONS = {
	TYPES:{
		GROUP_COUPON : "group coupon",
		COURSE_DISCOUNT : "course discount",
		SPECIAL_PACKAGE : "special package",
		SPECIAL_DISCOUNT : "special discount",
		VOUCHER:"voucher"
	},

	FORMIK: {
		BACKGROUND_COLOR:"backgroundColor",
		COUPON_TYPE:"couponType",
		VOUCHER: "Voucher",
		TEXT_COLOR: "textColor",
		BUTTON_COLOR:"buttonColor",
		CODE:"code",
		UNIT:"unit",
		DATE: "date",
		LIMIT: "limit",
		TEXT:"text",
		MEMBERS:"members",
		DISCOUNT:"discount",
		COURSES:"courses",
		SELECTED_COURSE:"selectedCourse",
		UNIVERSITY:"university",
		DEPARTMENTS:"departments",
		SELECTED_UNIVERSITIES:"selectedUniversities",
		SEGMENTS:{
			MIN:"min",
			MAX:"max",
			DISCOUNT:"discount",
			DISCOUNT_TYPE:"discount_type"
		},
		TIMER:{
			DURATION:"duration",
			TYPE:"type"
		},
		DISCOUNT_AMOUNT:"discount_amount",
		DISCOUNT_TYPE:"discount_type"


	},

	// REDUX: {
	// 	EDIT: "edit",
	// 	ADD: "add",
	// },

	// EDIT: "Edit",
	// ADD: "Add"


}
		
			