import React, { useEffect } from "react";

// import { AddInstructorProp } from "./interface";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { Button, Form, Modal } from "react-bootstrap";

// import { addCourse, course, editCourse } from "../api";
import styles from "../../instructor/styles/instructor.module.css";
import commonstyles from "../../instructor/styles/common.module.css";
//import commonstyles from "../../instructor/styles/common.module.css";
// import { CourseDetailsSchema } from "../validations";
//import { INSTRUCTOR } from "../../../utils/instructor";
//import { useAppSelector } from "../../../redux/hooks";
// import {
// 	DepartmentInterface,
// 	Instructor,
// 	UniversityInterface,
// } from "../../instructor/api/interface";
// import {
// 	getAllInstructorUniversity,
// 	getAllUniversities,
// } from "../../instructor/api";
// // import { AddCourseParam } from "../api/interface";
// import { PaginationInterface } from "../../../components/Pagination/interfaces";
// import { COURSES } from "../../../utils/courses";
// import { MESSAGES } from "../../../utils/messages";
// import { YUP } from "../../../utils/validations";
import { AddInstructorProp } from "../../course/modals/interface";
import {  getTimer, setTimer } from "../api";
import { COUPONS } from "../../../utils/coupons";
import { MESSAGES } from "../../../utils/messages";
// import { CouponDetails } from "../api/interface";
// import { useAppSelector } from "../../../redux/hooks";
// import { INSTRUCTOR } from "../../../utils/instructor";
// import { COUPONS } from "../../../utils/coupons";
// import { MESSAGES } from "../../../utils/messages";
// import { CouponDetailsSchema } from "../validations";
// import { DepartmentInterface, UniversityInterface } from "../../instructor/api/interface";
// import { getAllUniversities } from "../../instructor/api";
// import { COURSES } from "../../../utils/courses";
// import { courseListWithoutPagination } from "../../student/api";
// import { CourseDeatails } from "../../course/api/interface";

const ChangeTimer = ({ showModal, handleClose }: AddInstructorProp) => {

	useEffect(() => {
		getTimer().then((res)=>{
			const {duration, type}=res.data;
			timerDetailsFormik.setFieldValue(COUPONS.FORMIK.TIMER.DURATION, duration);
			timerDetailsFormik.setFieldValue(COUPONS.FORMIK.TIMER.TYPE, type);
 
		})
	
	}, [showModal]);

	const timerDetailsFormik = useFormik({
		

		initialValues: {
			duration:0,
			type:1

		},
		
		enableReinitialize: true,
		onSubmit: (values) => {
			
			toast.promise( 
				setTimer(values.duration, values.type),
				{
					pending: {
						render() {
							return MESSAGES.COUPON.TIMER.EDIT
										
						},
					},
					success: {
						render() {
							timerDetailsFormik.resetForm();
							handleClose();
							return MESSAGES.COUPON.TIMER.EDIT_SUCCESS
			
										
						},
					},
					error: {
						render() {
							return	MESSAGES.COUPON.TIMER.EDIT_INVALID
										
						},
					},
				}
			);
			
		
		},
	});

	// const selectedCourse = useAppSelector((state) => state.courseReducer);
	// const [instructors, setInstructors] = useState<Instructor[]>([]);
	// const [universities, setUniversities] = useState<UniversityInterface[]>([]);
	// const [department, setDepartment] = useState<DepartmentInterface[]>();
	// const [selectedDepartments, setSelectedDepartments] = useState<number[]>([]);
	// const [pagination, setPagination] = useState<PaginationInterface>({
	// 	page: 1,
	// 	limit: 10,
	// 	count: 0,
	// 	totalPages: 0,
	// });

	// const addInstructor = (id: number) => {
	// 	getAllInstructorUniversity(id).then((res) => {
	// 		setInstructors(res.data);
	// 		console.log(setPagination);
	// 	});
	// };
	// useEffect(() => {
	// 	getAllUniversities().then((res) => {
	// 		setUniversities(res?.data);
	// 	});
	// }, [showModal]);

	// useEffect(() => {
	// 	if (selectedCourse.editOrAdd === INSTRUCTOR.REDUX.EDIT) {
	// 		course(String(selectedCourse.id), pagination).then((res) => {
	// 			const { name, instructor, university, departments, full_price } = res.data;

	// 			courseDetailsFormik.setFieldValue(COURSES.FORMIK.COURSE, name);
	// 			courseDetailsFormik.setFieldValue(COURSES.FORMIK.PRICE, full_price)
	// 			addInstructor(university.id as number);
	// 			courseDetailsFormik.setFieldValue(
	// 				COURSES.FORMIK.INSTRUCTOR,
	// 				instructor.id
	// 			);
	// 			courseDetailsFormik.setFieldValue(
	// 				COURSES.FORMIK.UNIVERSITY,
	// 				university.id
	// 			);
	// 			addDepartments(Number(university.id));
	// 			const depList = departments.map((dep) => dep.department_id);
	// 			setSelectedDepartments(depList);
	// 		});
	// 	} else {
	// 		courseDetailsFormik.resetForm();
	// 		setDepartment([]);
	// 		setSelectedDepartments([]);
	// 	}
	// }, [selectedCourse.editOrAdd, showModal]);

	// const addDepartments = (id: Number) => {
	// 	if (id === 0) {
	// 		setDepartment([]);
	// 		return;
	// 	}
	// 	const selectedUniversity = universities.filter(
	// 		(uni: UniversityInterface) => {
	// 			return uni.id === id;
	// 		}
	// 	);
	// 	setDepartment(selectedUniversity[0]?.universityDepartments);
	// };
	// const handleUniversityChange = (e: any) => {
	// 	courseDetailsFormik.handleChange(e);
	// 	addDepartments(Number(e.target.value));
	// 	if (Number(e.target.value) === 0) {
	// 		setInstructors([]);
	// 		return;
	// 	}
	// 	addInstructor(Number(e.target.value));
	// };

	// const handleCheckboxClick = (e: React.ChangeEvent<HTMLInputElement>) => {
	// 	courseDetailsFormik.setFieldError(COURSES.FORMIK.DEPARTMENT, "");
	// 	if (e.target.checked === true) {
	// 		setSelectedDepartments([...selectedDepartments, Number(e.target.value)]);
	// 	} else {
	// 		const selectedDepartmentsCopy = selectedDepartments;
	// 		const selectedDepartmentsList = selectedDepartmentsCopy.filter((num) => {
	// 			return num !== Number(e.target.value);
	// 		});
	// 		setSelectedDepartments(selectedDepartmentsList);
	// 	}
	// };

	// const handleAddCourse = (e: React.FormEvent<HTMLFormElement>) => {
	// 	e.preventDefault();
	// 	courseDetailsFormik.submitForm();
	// };

	// const courseDetailsFormik = useFormik({
	// // 	initialValues: {
	// // 		course: "",
	// // 		instructor: 0,
	// // 		departments: [],
	// // 		university: 0,
	// // 	},
	// // 	enableReinitialize: true,
	// // 	onSubmit: (values) => {
	// // 		const payload: AddCourseParam = {
	// // 			instructor_id: Number(values.instructor),
	// // 			departments: selectedDepartments,
	// // 			university_id: values.university,
	// // 			name: values.course,
	// // 			isPublished: 1,
	// // 		};
	// // 		if (selectedDepartments.length === 0) {
	// // 			courseDetailsFormik.setFieldError(
	// // 				COURSES.FORMIK.DEPARTMENT,
	// // 				YUP.REQUIRED
	// // 			);
	// // 			return;
	// // 		}
	// // 		toast.promise(
	// // 			selectedCourse.editOrAdd === INSTRUCTOR.REDUX.ADD
	// // 				? addCourse(payload)
	// // 				: editCourse(payload, selectedCourse.id as number),
	// // 			{
	// // 				pending: {
	// // 					render() {
	// // 						return selectedCourse.editOrAdd === INSTRUCTOR.REDUX.ADD
	// // 							? MESSAGES.COURSE.ADD
	// // 							: MESSAGES.COURSE.EDIT;
	// // 					},
	// // 				},
	// // 				success: {
	// // 					render() {
	// // 						courseDetailsFormik.resetForm();
	// // 						handleClose();

	// // 						return selectedCourse.editOrAdd === INSTRUCTOR.REDUX.ADD
	// // 							? MESSAGES.COURSE.ADDED
	// // 							: MESSAGES.COURSE.EDITED;
	// // 					},
	// // 				},
	// // 				error: {
	// // 					render() {
	// // 						return selectedCourse.editOrAdd === INSTRUCTOR.REDUX.ADD
	// // 							? MESSAGES.COURSE.NOTADDED
	// // 							: MESSAGES.COURSE.NOTEDITED;
	// // 					},
	// // 				},
	// // 			}
	// // 		);
	// // 	},
	// // 	validationSchema: CourseDetailsSchema,
	//  });

	return (
		<Modal
			show={showModal}
			onHide={() => {
				handleClose();
			}}
		>
			<div className={styles["add-lead-from"]}>
				<Modal.Header closeButton>
					<Modal.Title>
						{"Change Timer"}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form onSubmit={()=>{}}>
					
						{/* {courseDetailsFormik.errors.course &&
              courseDetailsFormik.touched.course ? (
									<span className={commonstyles.error}>
										{courseDetailsFormik.errors.course}
									</span>
								) : null} */}
						
						
						{/* <div className={styles.fieldStyle}>

							<Form.Group className="from-group" controlId="formUniversity">
								<Form.Label>University</Form.Label>
								<Form.Control
									name={COURSES.FORMIK.UNIVERSITY}
									value={courseDetailsFormik.values.university}
									as="select"
									onChange={(e) => handleUniversityChange(e)}
								>
									<option value={0} key={0}>
                    Select University
									</option>
									{universities?.map((university, id) => (
										<option value={university.id} key={id}>
											{university.name}
										</option>
									))}
								</Form.Control>
							</Form.Group>
							{courseDetailsFormik.errors.university &&
              courseDetailsFormik.touched.university ? (
									<span className={commonstyles.error}>
										{courseDetailsFormik.errors.university}
									</span>
								) : null}
						</div>

						<div className={styles.fieldStyle}>
							<Form.Group className="from-group" controlId="formUniversity">
								<Form.Label>Instructor</Form.Label>
								<Form.Control
									name={COURSES.FORMIK.INSTRUCTOR}
									value={courseDetailsFormik.values.instructor}
									as="select"
									onChange={courseDetailsFormik.handleChange}
								>
									<option value={0} key={0}>
                    Select Instructor
									</option>
									{instructors?.map((instructor, id) => (
										<option value={instructor.id} key={id}>
											{instructor.full_name}
										</option>
									))}
								</Form.Control>
							</Form.Group>
							{courseDetailsFormik.errors.instructor &&
              courseDetailsFormik.touched.instructor ? (
									<span className={commonstyles.error}>
										{courseDetailsFormik.errors.instructor}
									</span>
								) : null}
						</div>

						{courseDetailsFormik.values.university > 0 && (
							<div className={styles.fieldStyle}>
								<Form.Label>Departments</Form.Label>

								{department?.map((res, id) => {
									return (
										<Form.Check
											key={id}
											className="from-check"
											value={res.id as number}
											type="checkbox"
											checked={
												selectedDepartments.includes(res.id as number)
													? true
													: false
											}
											onChange={handleCheckboxClick}
											label={res.name}
										></Form.Check>
									);
								})}

								{courseDetailsFormik.errors.departments &&
                courseDetailsFormik.touched.departments ? (
										<span className={commonstyles.error}>
											{courseDetailsFormik.errors.departments}
										</span>
									) : null}
							</div>
						)} */}

						{/* <Button variant="outline-secondary" className="mb-2">
                    <i className="fas fa-plus"></i> Choose More Department
                </Button> */}
						<hr />
						{/* <Button variant="outline-secondary" className="mb-2">
                    <i className="fas fa-plus"></i> Add More University
                </Button> */}
						{/* <Button className={`${styles.buttonss} mt-3`} type="submit">
							{selectedCourse.editOrAdd === INSTRUCTOR.REDUX.ADD
								? INSTRUCTOR.ADD
								: INSTRUCTOR.EDIT}
						</Button> */}
							

						{<>{<Form.Group className="from-group" controlId="formFirstName">
							<Form.Label>Duration</Form.Label>
							<Form.Control
								type="number"
								value={timerDetailsFormik.values.duration}
								placeholder="Duration"
								name={COUPONS.FORMIK.TIMER.DURATION}
								onChange={timerDetailsFormik.handleChange}
									
							/>
						</Form.Group>}
						{timerDetailsFormik.errors.duration &&
								timerDetailsFormik.touched.duration ? (
													  <span className={commonstyles.error}>
														  {timerDetailsFormik.errors.duration }
													  </span>
												  ) : null}
						</>}
					
						{<>{<Form.Group className="from-group" controlId="formFirstName">
							<Form.Label>Unit</Form.Label>
							<Form.Control
								name={COUPONS.FORMIK.TIMER.TYPE}
								value={timerDetailsFormik.values.type}
								as="select"
								onChange={timerDetailsFormik.handleChange}
							>
								<option value={1} key={0}>
                    Minute
								</option>
								<option value={2} key={2}>
                  Hour
								</option>
								<option value={3} key={5}>
                  Day
								</option>
							
							</Form.Control>
						</Form.Group>}
						{timerDetailsFormik.errors.type &&
								timerDetailsFormik.touched.type ? (
													  <span className={commonstyles.error}>
														  {timerDetailsFormik.errors.type }
													  </span>
												  ) : null}
						</>}
					

					</Form>
			
			
					<Button onClick={timerDetailsFormik.submitForm} className={`${styles.buttonss} mt-3`} type="submit">
						{"Change"}
					</Button>
				</Modal.Body>
			</div>
		</Modal>
	);
};

export default ChangeTimer;
