import { axios } from "../../../lib/axios";
import { API_ROUTES } from "../../../utils/api_routes";
import { Pagination } from "../../common/interface";
import { AddInvoice, INVOICE_RESPONSE } from "./interface";


export const invoiceList = (query: Pagination): Promise<INVOICE_RESPONSE> => {
	return axios.get(API_ROUTES.INVOICE.GET_INVOICES, { params: query });
};

export const addInvoice=(details:AddInvoice)=>{
	return axios.post(API_ROUTES.INVOICE.CREATE_INVOICE, details);

}