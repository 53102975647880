import * as Yup from "yup";

import { YUP } from "../../../utils/validations";

const passwordSchema = Yup.object().shape({
	currentPassword:  Yup.string().required(YUP.REQUIRED).test(YUP.PASSWORD_VALIDATION, YUP.PASSWORD, function(password){
		if (password.length < 6) {
			return false;
		  }
	
		  const lowercaseRegex = /[a-z]/;
		  const digitRegex = /\d/;
		  const symbolRegex = /[!@#$%^&*(),.?":{}|<>]/;
		
		  if (!lowercaseRegex.test(password)) {
			return false; 
		  }
		
		  if (!digitRegex.test(password)) {
			return false; 
		  }
		
		  if (!symbolRegex.test(password)) {
			return false; 
		  }
		
		  // All checks passed, the password is valid
		  return true;

	}),
	newPassword:  Yup.string().required(YUP.REQUIRED).test(YUP.PASSWORD_VALIDATION, YUP.PASSWORD, function(password){
		if (password.length < 6) {
			return false;
		  }
	
		  const lowercaseRegex = /[a-z]/;
		  const digitRegex = /\d/;
		  const symbolRegex = /[!@#$%^&*(),.?":{}|<>]/;
		
		  if (!lowercaseRegex.test(password)) {
			return false; 
		  }
		
		  if (!digitRegex.test(password)) {
			return false; 
		  }
		
		  if (!symbolRegex.test(password)) {
			return false; 
		  }
		
		  // All checks passed, the password is valid
		  return true;

	}),
	confirmPassword:Yup.string()
		.oneOf([Yup.ref("newPassword")], YUP.PASSWORD_MATCH)
		.required(YUP.REQUIRED),
});

export default passwordSchema;