import React from "react";
// import { useFormik } from "formik";
// import { toast } from "react-toastify";
import { Modal, Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";
 
// import { useParams } from "react-router-dom";
// import { useAppSelector } from "../../../redux/hooks";
// import { INSTRUCTOR } from "../../../utils/instructor";
// import { addDepartment, editDepartment, getDepartment } from "../api";
// import DepartmentDetailsSchema from "../validations";
import styles from "../../../../instructor/styles/instructor.module.css";
// import commonstyles from "../../instructor/styles/common.module.css";
// import { UNIVERSITY } from "../../../utils/university";
// import { MESSAGES } from "../../../utils/messages";
import { AddInstalmentProp } from "./interface";
import commonstyles from "../../../../instructor/styles/common.module.css";
// import { useAppSelector } from "../../../../../redux/hooks";
import { MESSAGES } from "../../../../../utils/messages";
import {  updateInstallment } from "../api";
import { useFormik } from "formik";
import { AddDateSchema } from "../validations";
import { STUDENT } from "../../../../../utils/student";

const UpdateDate = ({ showModal, handleClose, id }: AddInstalmentProp) => {

	const InstallmentDetailsFormik = useFormik({
		initialValues: {
			date:""
		},
		validationSchema: AddDateSchema,
		enableReinitialize: true,
		onSubmit: (values) => {

			toast.promise(updateInstallment(id, values.date), {
				pending: {
					render() {
						return MESSAGES.STUDENT.INSTALLMENT.UPDATE;
					},
				},
				success: {
					render({}) {
						handleClose()
						return MESSAGES.STUDENT.INSTALLMENT.UPDATED;
					},
				},
				error: {
					render() {
						return MESSAGES.STUDENT.INSTALLMENT.FAILED;
					},
				},
			})

          
		}


	})
	
	
	// const selectedDepartment = useAppSelector((state) => state.departmentReducer);
	// const { id } = useParams();

	// useEffect(() => {
	// 	if (selectedDepartment.editOrAdd === INSTRUCTOR.REDUX.EDIT) {
	// 		getDepartment(Number(selectedDepartment.id)).then((res) => {
	// 			const {
	// 				data: { name },
	// 			} = res;

	// 			departmentDetailsFormik.setFieldValue(UNIVERSITY.FORMIK.NAME, name);
	// 		});
	// 	} else {
	// 		departmentDetailsFormik.resetForm();
	// 	}
	// }, [showModal]);

	// const departmentDetailsFormik = useFormik({
	// 	initialValues: {
	// 		name: "",
	// 	},
	// 	enableReinitialize: true,
	// 	onSubmit: (values) => {
	// 		toast.promise(
	// 			selectedDepartment.editOrAdd === INSTRUCTOR.REDUX.ADD
	// 				? addDepartment(Number(id), values.name)
	// 				: editDepartment(Number(id), selectedDepartment.id, values.name),
	// 			{
	// 				pending: {
	// 					render() {
	// 						return selectedDepartment.editOrAdd === INSTRUCTOR.REDUX.ADD
	// 							? MESSAGES.DEPARTMENT.ADD
	// 							: MESSAGES.DEPARTMENT.EDIT;
	// 					},
	// 				},
	// 				success: {
	// 					render() {
	// 						departmentDetailsFormik.resetForm();
	// 						handleClose();

	// 						return selectedDepartment.editOrAdd === INSTRUCTOR.REDUX.ADD
	// 							? MESSAGES.DEPARTMENT.SUCCESS
	// 							: MESSAGES.DEPARTMENT.EDITED;
	// 					},
	// 				},
	// 				error: {
	// 					render() {
	// 						return selectedDepartment.editOrAdd === INSTRUCTOR.REDUX.ADD
	// 							? MESSAGES.DEPARTMENT.INVALID
	// 							: MESSAGES.DEPARTMENT.NOTEDITED;
	// 					},
	// 				},
	// 			}
	// 		);
	// 	},

	// 	validationSchema: DepartmentDetailsSchema,
	// });
	// const handleAddDepartment = (e: React.SyntheticEvent<HTMLFormElement>) => {
	// 	e.preventDefault();

	// 	departmentDetailsFormik.submitForm();
	// };
	const handleSubmit=()=>{
		InstallmentDetailsFormik.handleSubmit()
	}
	return (
		<Modal
			show={showModal}
			onHide={() => {
				handleClose();
			}}
		>
			<div>
				<Modal.Header closeButton>
					<Modal.Title>
						Update Installment
						{/* {selectedDepartment.editOrAdd === INSTRUCTOR.REDUX.EDIT
							? "Edit Department"
							: "Add N	ew Department"} */}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className={styles.fieldStyle}>
						<Form.Group className="from-group" controlId="formFirstName">
							<Form.Label>Date</Form.Label>
							<Form.Control
								type="date"
								value={InstallmentDetailsFormik.values.date}
								min={new Date().toISOString().slice(0, 10)}
								name={STUDENT.FORMIK.DATE}
								onChange={InstallmentDetailsFormik.handleChange}
							/>
						</Form.Group>
						{InstallmentDetailsFormik.errors.date &&
              InstallmentDetailsFormik.touched.date ? (
								<span className={commonstyles.error}>
									{InstallmentDetailsFormik.errors.date}
								</span>
							) : null}
					</div>	
					<Button className={`${styles.buttonss} mt-3`} onClick={handleSubmit}>
							Update
					</Button>
				</Modal.Body>
			</div>
		</Modal>
	)
	
};

export default UpdateDate;
