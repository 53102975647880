import React, { FormEvent, useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

import { AddInstructorProp } from "../interface";
import styles from "../../../../../instructor/styles/instructor.module.css";
import commonstyles from "../../../../../instructor/styles/common.module.css";
import { COURSES } from "../../../../../../utils/courses";
import { ChaptersDetailSchema } from "../../validations";
import { editChapter, getParticularChapter } from "../../api";
import { useAppSelector } from "../../../../../../redux/hooks";
import { MESSAGES } from "../../../../../../utils/messages";

const Edit = ({ showModal, handleClose }: AddInstructorProp) => {
	const selectedChapter = useAppSelector((state) => state.chapterReducer.id);
	const { id } = useParams();
	const [type, setType] = useState<string>("2");

	const handleEditChapter = (e: FormEvent) => {
		e.preventDefault();
		courseDetailsFormik.submitForm();
	};

	useEffect(() => {
		if (Number(selectedChapter) !== 0)
			getParticularChapter(Number(selectedChapter)).then((res) => {
				const {
					data: { price, type },
				} = res;
				setType(type as string);
				if (type === "2")
					courseDetailsFormik.setFieldValue(
						COURSES.FORMIK.PRICE,
						Number(price)
					);
			});
	}, [showModal]);

	const courseDetailsFormik = useFormik({
		initialValues: {
			price:0,
			share:0,
			shareUnit:1
		},
		enableReinitialize: true,
		onSubmit: (values) => {
			toast.promise(
				editChapter(
          selectedChapter as number,
          Number(values.price),
          Number(id)
				),
				{
					pending: {
						render() {
							return MESSAGES.CHAPTER.EDIT;
						},
					},
					success: {
						render() {
							courseDetailsFormik.resetForm();
							handleClose();

							return MESSAGES.CHAPTER.EDITED;
						},
					},
					error: {
						render() {
							return MESSAGES.CHAPTER.NOTEDITED;
						},
					},
				}
			);
		},
		validationSchema: ChaptersDetailSchema,
	});
	return (
		<Modal
			show={showModal}
			onHide={() => {
				handleClose();
			}}
		>
			<div className={styles["add-lead-from"]}>
				<Modal.Header closeButton>
					<Modal.Title>Edit Chapter</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form onSubmit={handleEditChapter}>
						{type === "2" && (<>
							<div className={styles.fieldStyle}>
								<Form.Group className="from-group" controlId="formFirstName">
									<Form.Label>Price</Form.Label>
									<Form.Control
										onKeyDown={(e) => {
											if (e.key === "e" || e.key === "E") {
												e.preventDefault();
											}
										}}
										type="number"
										placeholder="Price"
										value={courseDetailsFormik.values.price}
										name={COURSES.FORMIK.PRICE}
										onChange={courseDetailsFormik.handleChange}
									/>
								</Form.Group>
								{courseDetailsFormik.errors.price &&
                courseDetailsFormik.touched.price ? (
										<span className={commonstyles.error}>
											{courseDetailsFormik.errors.price}
										</span>
									) : null}
							</div>
							<div className={styles.fieldStyle}>
								<Form.Group className="from-group" controlId="formFirstName">
									<Form.Label>Instructor Share</Form.Label>
									<Form.Control
										type="number"
										placeholder="Instructor Share"
										value={courseDetailsFormik.values.share}
										name={COURSES.FORMIK.SHARE}
										onChange={courseDetailsFormik.handleChange}
									/>
								</Form.Group>
								{courseDetailsFormik.errors.share &&
              courseDetailsFormik.touched.share ? (
										<span className={commonstyles.error}>
											{courseDetailsFormik.errors.share}
										</span>
									) : null}
							</div>	
							<div className={styles.fieldStyle}>

								<Form.Group className="from-group" controlId="formUniversity">
									<Form.Label>Instructor Share Unit</Form.Label>
									<Form.Control
										name={COURSES.FORMIK.SHARE_UNIT}
										value={Number(courseDetailsFormik.values.shareUnit)}
										as="select"
										onChange={courseDetailsFormik.handleChange}
									>
										<option value={1} key={1}>
                    %
										</option>
										<option value={2} key={2}>
                    KD
										</option>
									
									</Form.Control>
								</Form.Group>
								{courseDetailsFormik.errors.shareUnit &&
              courseDetailsFormik.touched.shareUnit ? (
										<span className={commonstyles.error}>
											{courseDetailsFormik.errors.shareUnit}
										</span>
									) : null}
							</div>	
						</>)}

						<Button
							disabled={type === "1"}
							className={`${styles.buttonss} mt-3`}
							type="submit"
						>
							{type === "1" ? "This is free Chapter" : "Edit"}
						</Button>
					</Form>
				</Modal.Body>
			</div>
		</Modal>
	);
};

export default Edit;
