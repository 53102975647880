import React, { FormEvent, useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import { toast } from "react-toastify";

import { AddInstructorProp } from "../interface";
import styles from "../../../instructor/styles/instructor.module.css";
import commonstyles from "../../../instructor/styles/common.module.css";
import { AddonDetailSchema } from "../../validations";
import { addAddon, editAddon, getSpecificAddons } from "../../api";
import { useAppSelector } from "../../../../redux/hooks";
import { MESSAGES } from "../../../../utils/messages";
import { INSTRUCTOR } from "../../../../utils/instructor";
import { COURSES } from "../../../../utils/courses";

const Add = ({ showModal, handleClose }: AddInstructorProp) => {
	const selectedAddon = useAppSelector((state) => state.addonReducer);

	const handleEditChapter = (e: FormEvent) => {
		e.preventDefault();
		AddOnFormik.submitForm();
	};

	useEffect(() => {
		if (selectedAddon.editOrAdd === INSTRUCTOR.REDUX.EDIT) {
			getSpecificAddons(String(selectedAddon.id)).then((res) => {
				const { name, type, price} = res.data;

				AddOnFormik.setFieldValue(COURSES.ADDONS.FORMIK.NAME, name);
				AddOnFormik.setFieldValue(COURSES.ADDONS.FORMIK.TYPE, type);
				AddOnFormik.setFieldValue(COURSES.ADDONS.FORMIK.PRICE, price);

			});
		} else {
			AddOnFormik.resetForm();
		
		}
	}, [selectedAddon.editOrAdd, showModal]);

	const AddOnFormik = useFormik({
		initialValues: {
			price:"",
			name:"",
			type:1
		},
		enableReinitialize: true,
		onSubmit: (values) => {
			toast.promise(
				selectedAddon.editOrAdd===INSTRUCTOR.REDUX.ADD?addAddon(values):editAddon(values, String(selectedAddon.id))
				,
				{
					pending: {
						render() {
							return selectedAddon.editOrAdd===INSTRUCTOR.REDUX.ADD?MESSAGES.COURSE.ADDON.ADD:MESSAGES.COURSE.ADDON.EDIT;
						},
					},
					success: {
						render() {
							AddOnFormik.resetForm();
							handleClose();

							return selectedAddon.editOrAdd===INSTRUCTOR.REDUX.ADD?MESSAGES.COURSE.ADDON.ADDED:MESSAGES.COURSE.ADDON.EDITED;
						},
					},
					error: {
						render() {
							return selectedAddon.editOrAdd===INSTRUCTOR.REDUX.ADD?MESSAGES.COURSE.ADDON.NOTADDED:MESSAGES.COURSE.ADDON.NOTEDITED;
						},
					},
				}
			);
		},
		validationSchema: AddonDetailSchema,
	});
	return (
		<Modal
			show={showModal}
			onHide={() => {
				handleClose();
			}}
		>
			<div className={styles["add-lead-from"]}>
				<Modal.Header closeButton>
					<Modal.Title>Add Add-On</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form onSubmit={handleEditChapter}>
						 <>
							
							<div className={styles.fieldStyle}>
								<Form.Group className="from-group" controlId="formFirstName">
									<Form.Label>Name</Form.Label>
									<Form.Control
							
										
										type="text"
										placeholder="Name"
										value={AddOnFormik.values.name}
										name={COURSES.ADDONS.FORMIK.NAME}
										onChange={AddOnFormik.handleChange}
									/>
								</Form.Group>
								{AddOnFormik.errors.name &&
                AddOnFormik.touched.name ? (
										<span className={commonstyles.error}>
											{AddOnFormik.errors.name}
										</span>
									) : null}
							</div>
							<div className={styles.fieldStyle}>
								<Form.Group className="from-group" controlId="formFirstName">
									<Form.Label>Price</Form.Label>
									<Form.Control
										onKeyDown={(e) => {
											if (e.key === "e" || e.key === "E") {
												e.preventDefault();
											}
										}}
										type="number"
										placeholder="Price"
										value={AddOnFormik.values.price}
										name={COURSES.ADDONS.FORMIK.PRICE}
										onChange={AddOnFormik.handleChange}
									/>
								</Form.Group>
								{AddOnFormik.errors.price &&
               AddOnFormik.touched.price ? (
										<span className={commonstyles.error}>
											{AddOnFormik.errors.price}
										</span>
									) : null}
							</div>
							<div className={styles.fieldStyle}>

								<Form.Group className="from-group" controlId="formUniversity">
									<Form.Label>Type</Form.Label>
									<Form.Control
										name={COURSES.ADDONS.FORMIK.TYPE}
										value={Number(AddOnFormik.values.type)}
										as="select"
										onChange={AddOnFormik.handleChange}
									>
										<option value={1} key={1}>
                   Online
										</option>
										<option value={2} key={2}>
                    In-person
										</option>
									
									</Form.Control>
								</Form.Group>
								
							</div>	
						</>

						<Button
							
							className={`${styles.buttonss} mt-3`}
							type="submit"
						>
							Add
						</Button>
					</Form>
				</Modal.Body>
			</div>
		</Modal>
	);
};

export default Add;
