import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import { toast } from "react-toastify";

import styles from "../../../instructor/styles/instructor.module.css";
import commonstyles from "../../../instructor/styles/common.module.css";
import { AddUniversityProp } from "../../../university/modals/interface";
import { changePassword } from "../../api/auth";
import { ChangePasswordPayload } from "../../api/interface";
import passwordSchema from "../../validations/changePassword";
import { MESSAGES } from "../../../../utils/messages";
import { AUTH } from "../../../../utils/auth";

const ChangePassword = ({ showModal, handleClose }: AddUniversityProp) => {
	const passwordDetailsFormik = useFormik({
		initialValues: {
			currentPassword: "",
			newPassword: "",
			confirmPassword: "",
		},
		enableReinitialize: true,
		validationSchema: passwordSchema,
		onSubmit: (values) => {
			const payload: ChangePasswordPayload = {
				old_password: values.currentPassword,
				new_password: values.newPassword,
			};
			toast.promise(
				changePassword(payload),

				{
					pending: {
						render() {
							return MESSAGES.PASSWORD.CHANGE;
						},
					},
					success: {
						render() {
							handleClose();
							passwordDetailsFormik.resetForm();
							return MESSAGES.PASSWORD.CHANGED;
						},
					},
					error: {
						render() {
							return MESSAGES.PASSWORD.NOTCHANGED;
						},
					},
				}
			);
		},
	});
	return (
		<Modal
			show={showModal}
			onHide={() => {
				handleClose();
			}}
		>
			<div>
				<Modal.Header closeButton>
					<Modal.Title>Change Password</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<div className={styles.fieldStyle}>
							<Form.Group className="from-group">
								<Form.Label>Current Password</Form.Label>
								<Form.Control
									type="password"
									placeholder="Current Password"
									value={passwordDetailsFormik.values.currentPassword}
									name={AUTH.PASSWORD_FORMIK.CURRENT_PASSWORD}
									onChange={passwordDetailsFormik.handleChange}
								/>
							</Form.Group>
							{passwordDetailsFormik.errors.currentPassword &&
              passwordDetailsFormik.touched.currentPassword ? (
									<span className={commonstyles.error}>
										{passwordDetailsFormik.errors.currentPassword}
									</span>
								) : null}
						</div>
						<div className={styles.fieldStyle}>
							<Form.Group className="from-group">
								<Form.Label>New Password</Form.Label>
								<Form.Control
									type="password"
									placeholder="New Password"
									value={passwordDetailsFormik.values.newPassword}
									name={AUTH.PASSWORD_FORMIK.NEW_PASSWORD}
									onChange={passwordDetailsFormik.handleChange}
								/>
							</Form.Group>
							{passwordDetailsFormik.errors.newPassword &&
              passwordDetailsFormik.touched.newPassword ? (
									<span className={commonstyles.error}>
										{passwordDetailsFormik.errors.newPassword}
									</span>
								) : null}
						</div>
						<div className={styles.fieldStyle}>
							<Form.Group className="from-group">
								<Form.Label>Confirm Password</Form.Label>
								<Form.Control
									type="password"
									placeholder="Confirm Password"
									value={passwordDetailsFormik.values.confirmPassword}
									name={AUTH.PASSWORD_FORMIK.CONFIRM_PASSWORD}
									onChange={passwordDetailsFormik.handleChange}
								/>
							</Form.Group>
							{passwordDetailsFormik.errors.confirmPassword &&
              passwordDetailsFormik.touched.confirmPassword ? (
									<span className={commonstyles.error}>
										{passwordDetailsFormik.errors.confirmPassword}
									</span>
								) : null}
						</div>
						<Button
							onClick={() => passwordDetailsFormik.handleSubmit()}
							className={`${styles.buttonss} mt-3`}
						>
              Change Password
						</Button>
					</Form>
				</Modal.Body>
			</div>
		</Modal>
	);
};

export default ChangePassword;
