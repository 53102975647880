import React, { useEffect, useState } from "react"
import moment from "moment";
// import { faSearch } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Spinner } from "react-bootstrap";

import Layout from "../../../components/Layout"
import commonStyles from "../../common/styles/common.module.css";
import { PaginationInterface } from "../../../components/Pagination/interfaces";
// import { useNavigate } from "react-router-dom";
import { transactionGraph, transactionsList } from "../api";
import Pagination from "../../../components/Pagination";
import { TransactionResponse } from "../api/interfaces";
import { MONTHS } from "../../../utils/constants";
import ColumnGraph from "../../../components/Graph/ColumnGraph";
import { Student } from "../../student/api/interface";
import { studentList } from "../../student/api";
import PieGraph from "../../../components/Graph/PieGraph";


const Transactions = () => {

	const [pagination, setPagination] = useState<PaginationInterface>({
		page: 1,
		limit: 10,
		count: 0,
		totalPages: 0,
	});
	const [courseCount, setCourseCount]=useState<number[]>([]);
	const [courseCost, setCourseCost]=useState<number[]>([])
	const [students, setStudents] = useState<Student[]>([]);
	const [graphLoader, setGraphLoader]=useState<boolean>(true)

	// const navigate = useNavigate();


	const handleSearch=()=>{
		setPagination({
			page: 1,
			limit: 10,
			count: 0,
			totalPages: 0,
		})
		refresh()

	}
	const handleGraphStudent=(id:number)=>{
		refreshStudentList(id)

	}
	const refreshStudentList=(id:number)=>{
		setGraphLoader(true)
		transactionGraph(id).then((res)=>{
			const arr=res.purchasedCoursesCount.map((course)=>{
				return course.count
			})
			const carr=res.purchasedCoursesSum.map((course)=>{
				return course.total_amount
			})
			setCourseCount(arr)
			setCourseCost(carr)
			setGraphLoader(false)
		})
	}

	useEffect(() => {
		studentList().then((res)=>{
			setStudents(res.data)

		})

		refreshStudentList(0)
	}, [])


	//const [instructors, setInstructors] = useState<Instructor[]>([]);
	const [transaction, setTransactions] = useState<TransactionResponse[]>([]);
	const [searchString, setSearchString] = useState<string>("");
	const [loading, setLoading] = useState<boolean>(true);
	const [monthFilter, setMonthFilter]=useState<number>(0);
	const [sortStatus, setSortStatus]=useState<string>("")
    const [statusFilter,setStatusFilter]=useState<string>("Select")
	const refresh = () => {
		setLoading(true);
		const query = {
			per_page: pagination.limit,
			page: pagination.page,
			month:monthFilter,
			sort_by_date:sortStatus,
			search:searchString,
			transaction_status: statusFilter
		};

		transactionsList(query).then((res) => {
			const {
				paginate: { total, last_page },
				data,
			} = res;
			setPagination({ ...pagination, count: total, totalPages: last_page });
			setTransactions(data);
			setLoading(false);
		});
	};

	const filterMonth=(id:number)=>{
		setMonthFilter(id);
      

	}
	const handleSort=(type:string)=>{
		setSortStatus(type)
       

	}

	useEffect(() => {
		refresh();
	}, [pagination.page]);
	useEffect(() => {
		console.log(searchString, "ssssss")
		handleSearch()
	}, [searchString]);

	useEffect(() => {
		setPagination({
			page: 1,
			limit: 10,
			count: 0,
			totalPages: 0,
		})
		refresh()
	}, [sortStatus, monthFilter,statusFilter])


	return (
		<Layout>
			<div className={commonStyles["outtre-pad"]}>
				<div className={commonStyles["heaing-filter"]}>
					<h3>Transactions</h3>
					{/* let newStr = str.replace(/\+/g, ' '); */}
					<div className={commonStyles["filter-left"]}>
						<Form.Select    onChange={(e)=>{console.log(e.target.selectedOptions[0].text, "iddd"); handleGraphStudent(Number(e.target.value)); setSearchString(String(e.target.selectedOptions[0].text).replace("+", " "))}} >
							<option value={0} selected>Select Student</option>
							{students.map((student, id)=><option key={id}    value={student.id}>{student.full_name}</option>)}
  
						</Form.Select>
						<span>Month:<select value={monthFilter} onChange={(e)=>{filterMonth(Number(e.target.value))}} >
							<option value={0} selected>Month</option>
							{MONTHS.map((month, id)=><option key={id} value={month.id}>{month.name}</option>)}
  
						</select></span>
						<span>Filter:
							<select value={sortStatus} onChange={(e)=>{handleSort(e.target.value)}} >
								<option value="" selected>Sort</option>
								<option value="asc">Latest First</option>
								<option value="desc">Oldest First</option>
  
							</select>
						</span>
						<span>Status:
							<select value={statusFilter} onChange={(e)=>{setStatusFilter(e.target.value)}} >
								<option value="Select" selected>Select</option>
								<option value="Pending">Pending</option>
								<option value="Paid">Paid</option>
								<option value="Canceled">Canceled</option>
							</select>
						</span>
						
					</div>
				</div>

				{!loading ? (
					<div className={`light-theme-table ${commonStyles["table-data"]}`}>
						<div className={`${commonStyles["property-head"]} pb-1`}>
							<ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
								<li className="nav-item" role="presentation">
									{/* <button
									className={`nav-link ${commonStyles.activeTab}`}
									id="pills-home-tab"
									data-bs-toggle="pill"
									data-bs-target="#pills-home"
									type="button"
									role="tab"
									aria-controls="pills-home"
									aria-selected="true"
								>
                  All
								</button> */}
								</li>
							</ul>
							{/* <div className={commonStyles["filter-pro"]}>
								<button
									onClick={() => navigate(APP_ROUTES.STUDENTS_ADD)}
									className={commonStyles["add-lead"]}
								>
									<FontAwesomeIcon icon={faPlus} /> Add New Student
								</button>
							</div> */}
                    
						</div>
						<div className="tab-content" id="pills-tabContent">
							<div
								className="tab-pane fade show active"
								id="pills-home"
								role="tabpanel"
								aria-labelledby="pills-home-tab"
							>
								<table>
									<thead>
										<tr>
											<th>Name</th>
											<th className="text-center">University</th>
											<th>Transaction Number</th>
											<th>Transaction Amount</th>
											<th className="text-center">Transaction Status</th>
											<th className="text-center">Transaction Date</th>	
										</tr>
									</thead>
									<tbody>
										{transaction.map((item) => (
											<tr key={item.id}>
												<td>
													
													{item.user.full_name}
												</td>
												
												<td className="text-center">{item.user.student.university?.name}</td>
												
                                                
												<td className="text-center">
													{item.transaction_number}
												</td>
												<td className="text-center">
													{item.amount}{"KD"}
												</td>
												<td className="text-center">
													{item.transaction_status}
												</td>
												<td>
													{moment(item.transaction_date).format("MMM DD, YYYY")}
												</td>
												{/* <td>
												<span className={commonStyles["bl-shd"]}>
													{"View Courses"}
												</span>
											</td> */}
												{/* <td>
													<Dropdown
														drop={"down-centered"}
														className={commonStyles.tddropdown}
													>
														<Dropdown.Toggle>
															<FontAwesomeIcon icon={faEllipsisH} />
														</Dropdown.Toggle>

														<Dropdown.Menu>
															<Dropdown.Item
																onClick={() =>
																	navigate(
																		`${APP_ROUTES.STUDENTS_LIST}/edit/${item.id}`
																	)
																}
															>
																<FontAwesomeIcon
																	className={commonStyles.iconmargin}
																	icon={faEdit}
																	size="xs"
																/>
                                Edit
															</Dropdown.Item>
															<Dropdown.Item
																onClick={() => handleRemoveStudent(item.id)}
															>
																<FontAwesomeIcon
																	className={commonStyles.iconmargin}
																	size="xs"
																	icon={faTrash}
																/>
                                Remove
															</Dropdown.Item>
															<Dropdown.Item
																onClick={() =>
																	navigate(
																		`${ROUTE_CONSTANTS.STUDENTS}/${item.id}`
																	)
																}
															>
																<FontAwesomeIcon
																	size="xs"
																	className={commonStyles.iconmargin}
																	icon={faInfoCircle}
																/>
                                View More
															</Dropdown.Item>
														</Dropdown.Menu>
													</Dropdown>
												</td> */}
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				) : (
					<div className="d-flex justify-content-center">
						<Spinner animation="border" role="status">
						</Spinner>
					</div>
				)}
			</div>
			<Pagination
				paginationState={pagination}
				setPaginationState={setPagination}
			/>
			
			 {!graphLoader?(<div className={commonStyles.flexGraph}><ColumnGraph data={courseCount} name={"courses count"}/><PieGraph data={courseCost} name={"courses count"}/></div>):(
			 	<div className="d-flex justify-content-center">
			 		<Spinner animation="border" role="status">
			 		</Spinner>
				</div>
			)}
		</Layout>
	)
}

export default Transactions