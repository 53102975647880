import * as Yup from "yup";
import { YUP } from "../../../utils/validations";
import { COUPONS } from "../../../utils/coupons";


export const CouponDetailsSchema = Yup.object().shape({
	couponType:Yup.number().required(YUP.REQUIRED),
	code: Yup.string().required(YUP.REQUIRED),
	date: Yup.string().required(YUP.REQUIRED),
	members:Yup
		.number()
		.when(COUPONS.FORMIK.COUPON_TYPE, {
			is: 1,
			then: () => Yup.number().min(1, YUP.GREATER0)
				.required(YUP.REQUIRED)
				
			
		}),
	discount_amount:Yup
		.number()
		.when(COUPONS.FORMIK.COUPON_TYPE, {
			is: 6,
			then: () => Yup.number().min(1, YUP.GREATER0)
				.required(YUP.REQUIRED)
				
			
		}),
	selectedUniversities: Yup.array().of(
		Yup.object().shape({

			  discount:Yup
			  .number().min(1, YUP.GREATER0).required(YUP.REQUIRED)
			  .when(COUPONS.FORMIK.DISCOUNT_TYPE, {
				  is: "2",
				  then: () => Yup.number()
					  .required(YUP.REQUIRED)
					  .min(1, YUP.GREATER0)
					  .max(99, YUP.LESS100)
				  
			  }),
			//   discount: Yup.number().min(1, 'Discount must be greater than 0').required('Discount is required'),
		})
		  ),
			
	
	courses:Yup.array()
		.of(Yup.number())
		.when(COUPONS.FORMIK.COUPON_TYPE, {
			is: (value: number) => value === 2 || value === 3,
			then: () => Yup.array()
				.of(Yup.number())
				.min(1, YUP.ARRAYEMPTY)

		}),
	
	text:Yup
		.string()
		.when(COUPONS.FORMIK.COUPON_TYPE, {
			is: 5,
			then: () => Yup.string()
				.required(YUP.REQUIRED)
				
			
		}),
	limit:Yup.mixed()
		.when(COUPONS.FORMIK.COUPON_TYPE, {
			is: (value: number) => value === 2 || value === 5,
			then: () => Yup.number().min(1, YUP.GREATER0).required(YUP.REQUIRED),
				
			
		}),
	selectedCourse:Yup
		.number()
		.when(COUPONS.FORMIK.COUPON_TYPE, {
			is: (value: number) => value === 4,
			then: () => Yup.number().min(1, YUP.GREATER0)
				.required(YUP.REQUIRED)
				
			
		}),


})