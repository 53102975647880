import { axios } from "../../../../../lib/axios";
import { API_ROUTES } from "../../../../../utils/api_routes";
import { Pagination } from "../../../../common/interface";
import { InstallmentsResponse } from "./interface";
// import {
// 	InstructorListResponse,
// 	UniversityListResponse,
// 	AddInstructorPayload,
// 	AddInstructorResponse,
// 	RemoveInstructorPayload,
// 	RemoveInstructorRespose,
// 	GetInstructorResponse
// } from "./interface";

export const instalmentList = (query: Pagination, user_id:number, order_id:number): Promise<InstallmentsResponse> => {
	return axios.get(API_ROUTES.STUDENT.GET_INSTALMENT, { params: {...query, user_id, order_id} });
};
export const changeStatus=(id:string, status:number)=>{
	return axios.post(API_ROUTES.STUDENT.EDIT_INSTALMENT_STATUS, {id:String(id), status});
}
export const updateInstallment=(id:string, due_date:string)=>{
	return axios.post(API_ROUTES.STUDENT.UPDATE_INSTALMENT, {id, due_date})
}
export const deleteInstallment=(id:string)=>{
	return axios.get(`${API_ROUTES.STUDENT.DELETE_INSTALMENT}/${id}`)
}
// export const instructorListWithoutPagination=():Promise<InstructorListResponse>=>{
// 	return axios.get(API_ROUTES.INSTRUCTOR.GETLIST);
// }

// export const getAllUniversities = (): Promise<UniversityListResponse> => {
// 	return axios.get(API_ROUTES.COMMON.GETALLUNIVERSITIES);
// }
// export const getAllInstructorUniversity=(id:number):Promise<InstructorListResponse>=>{
// 	return axios.get(`${API_ROUTES.INSTRUCTOR.GETLIST}`, {params:{university_id:id}})
// }

// export const addInstructor = (query: AddInstructorPayload): Promise<AddInstructorResponse> => {
// 	return axios.post(API_ROUTES.INSTRUCTOR.ADDINSTRUCTOR, query);
// }

// export const removeInstructor = (query: RemoveInstructorPayload): Promise<RemoveInstructorRespose> => {
// 	return axios.delete(`${API_ROUTES.INSTRUCTOR.REMOVEINSTRUCTOR}${query.id}`);
// }

// export const getInstructor = (query: RemoveInstructorPayload): Promise<GetInstructorResponse> => {
// 	return axios.get(`${API_ROUTES.INSTRUCTOR.GETINSTRUCTOR}${query.id}`);
// }
// export const editInstructor = (id: Number, query: AddInstructorPayload): Promise<RemoveInstructorRespose> => {
// 	return axios.post(`${API_ROUTES.INSTRUCTOR.EDITINSTRUCTOR}${id}`, query);
// }
