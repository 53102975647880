import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { Modal, Form, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";

import { useAppSelector } from "../../../redux/hooks";
import { INSTRUCTOR } from "../../../utils/instructor";
import {  addDepartment, addStory, editDepartment, getDepartment } from "../api";
import  { StorySchema } from "../validations";
import styles from "../../instructor/styles/instructor.module.css";
import commonstyles from "../../instructor/styles/common.module.css";
import { UNIVERSITY } from "../../../utils/university";
// import { MESSAGES } from "../../../utils/messages";
import { AddUniversityProp, Department } from "./interface";
import { MESSAGES } from "../../../utils/messages";
import { uploadImage } from "../../common/utils";

const DisplayStory = ({ showModal, handleClose, link }: any) => {
    const [image,setImage]=useState<String>("")

    useEffect(() => {
        setImage(link)
      
    }, [link])
    


	return (
		<Modal
			show={showModal}
			onHide={() => {
				handleClose();
			}}
		>
			<div>
				<Modal.Header closeButton>
					<Modal.Title>
						Story
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<img src={image as string}/>
				</Modal.Body>
			</div>
		</Modal>
	);
};

export default DisplayStory;
