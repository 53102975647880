import React, { useEffect} from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import styles from "../styles/auth.module.css";
import commonStyles from "../styles/common.module.css";
import { Logo } from "../../../assets/images";
import { login } from "../api/auth";
import { LoginAdminSuccessResponse } from "../api/interface";
import loginSchema from "../validations/login";
import { MESSAGES } from "../../../utils/messages";
import { APP_ROUTES } from "../../../utils/routes";
import useAuth from "../../../lib/hooks/useAuth";
import { getProfile } from "../../../components/api";
import storage from "../../../utils/storage";
import { AUTH } from "../../../utils/auth";

const Login = () => {
	const navigate = useNavigate();
	const { loginUser } = useAuth();

	useEffect(() => {
		if(storage.validateRememberMe())
		{
			toast.promise(
				getProfile(),
				{
					pending: {
						render() {
							return MESSAGES.LOGIN.VERIFY
						}
					},
					success: {
						render() {
							navigate(APP_ROUTES.INSTRUCTORS_LIST);
							return MESSAGES.LOGIN.SUCCESS	
						}
					},
					error: {
						render() {
							return MESSAGES.LOGIN.INVALID
						}
					}
				}
			);

		}
		
	}, []);

	const changeRememberMe=(e: React.ChangeEvent<HTMLInputElement>)=>{
		if(e.target.checked) { 
			storage.storeRememberMe(AUTH.TRUE)
		 }else{ 
			storage.storeRememberMe(AUTH.FALSE)
		 }
	}

	const loginFormik = useFormik({
		initialValues: { email: "", password: "" },
		onSubmit: (values, { setSubmitting }) => {

			const payload = {
				email: values.email,
				password: values.password,
			};

			toast.promise(
				login(payload),
				{
					pending: {
						render() {
							return MESSAGES.LOGIN.VERIFY;
						}
					},
					success: {
						render({ data }) {
							let response = data as LoginAdminSuccessResponse;

							setSubmitting(false);
							loginUser(response.authorization.token);

							loginFormik.resetForm();

							navigate(APP_ROUTES.INSTRUCTORS_LIST);
							return MESSAGES.LOGIN.SUCCESS
						}
					},
					error: {
						render() {
							setSubmitting(false);

							return MESSAGES.LOGIN.INVALID
						}
					}
				}
			);
		},
		validationSchema: loginSchema,
	});

	return (
		<div className={`${styles["login-page"]} container-fluid`}>
			<div className="row">
				<div className="col-md-6">
					<div className={styles["login-right"]}>
						<img src={Logo} alt="" />
						<p>Welcome.<br />
							<span>Start your journey now<br /> with <b>Bright Future</b></span></p>
					</div>
				</div>
				<div className="col-md-6">
					<div className={styles["login-left"]}>
						<h2>Login</h2>
						<form onSubmit={loginFormik.handleSubmit}>
							<div className="form-group">
								<label>Email</label>
								<input
									type="text"
									placeholder="Email"
									name="email"
									onChange={loginFormik.handleChange}
									onBlur={loginFormik.handleBlur}
									value={loginFormik.values.email}
								/>
								{loginFormik.touched.email && loginFormik.errors.email ?
								 <p className={commonStyles.error}>{loginFormik.errors.email}</p> : null}
							</div>
							<div className="form-group">
								<label>Password</label>
								<input
									type="password"
									placeholder="Enter password"
									name="password"
									onChange={loginFormik.handleChange}
									onBlur={loginFormik.handleBlur}
									value={loginFormik.values.password}
								/>
								<span><img src="assets/images/eye.png" alt="" /></span>
								{loginFormik.touched.password && loginFormik.errors.password ? 
									<p className={commonStyles.error}>{loginFormik.errors.password}</p> : null}
							</div>
							<div className={`form-group ${styles["forget-ass"]}`}>
								<div className={styles["switch-btn"]}>
									<div className="form-check form-switch">
										<input className="form-check-input" onChange={changeRememberMe} type="checkbox" role="switch"
										  />
										<label className="form-check-label" htmlFor="flexSwitchCheckDefault">Remember me</label>
									</div>
								</div>
								{/* <a href="/">Forgot Your Password?</a> */}
							</div>
							<div className="form-group">
								<input type="submit" value="Sign in" disabled={loginFormik.isSubmitting} />
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Login;