import React from 'react'

const index = () => {
  return (
    <div style={{padding:"20px"}}>
        <h2 style={{textAlign:"center"}}>Terms & Conditions for Bright Future Institute App</h2>
        <br/>

    Last Updated: [9/6/2024]
    <br/>

    
    Welcome to the Bright Future Institute mobile application (the "App"). By downloading, accessing, or using the App, you agree to comply with and be bound by these Terms & Conditions (the "Terms"). The Terms govern your use of the App, our website, and any services provided through the platform (collectively, the "Services"). The Services include, but are not limited to, class registration for physical and online courses, access to supplementary digital materials (such as videos and notes), the option to purchase add-ons (including printed notes and in-person revision sessions), and participation in communication tools like dedicated WhatsApp groups. If you do not agree to these Terms, please refrain from using the App.
    <br/>
    ________________________________________
    <h4>1.	Acceptance of Terms</h4>
    By using the Bright Future Institute App, you confirm that you have read, understood, and agree to be bound by these Terms. Bright Future Institute reserves the right to modify these Terms at any time, and such changes will be effective upon posting within the App. Continued use of the App after such updates constitutes your acceptance of the revised Terms.
    <h4>2. Overview of Services </h4>
    Bright Future Institute is an educational platform offering:
    <br/>
    •	Physical Classes: Both online and in-person classes requiring physical attendance at our institute.
    <br/>
    •	Supplementary Digital Resources: Free videos, notes, and learning materials provided exclusively as a complement to students who have registered for physical classes or in-person courses. These resources are not sold independently and are intended only to enhance the physical learning experience.
    <br/>
    <h4>3. Registration and Subscriptions</h4>
    <h5>3.1 Account Registration</h5>
    To access certain features of the App, users must register for an account. You agree to provide accurate, current, and complete information during registration and to update this information as necessary. You are responsible for keeping your login credentials confidential and for all activities that occur under your account.
    <h5>3.2 Subscription Models</h5>
    The App offers two primary subscription options:
    <br/>
    •	Online Subscription: Students register for physical classes at the Institute and, as part of the subscription, gain complementary access to digital resources. These digital materials are provided free of charge to support the physical course and are not sold separately. Additional physical add-ons, such as printed notes and in-person revision sessions, can be purchased externally.
   <br/> •	In-Person Subscription: Provides access to physical attendance at the Institute, printed notes, in-person revision sessions, free digital resources, and other add-ons like private instructor sessions.
    
    <h4>4. Payment and Fees</h4>
    <h5>4.1 External Payment Methods</h5>
    Payments for physical class subscriptions and any related physical add-ons, such as printed notes or in-person revision sessions, are processed externally through MyFatoorah API . The app does not offer standalone digital products for purchase.
    <h5>4.2 Payment Terms</h5>
    Payments for subscriptions and services are due at the time of registration. For some services, installment payment option is also available where a student can select number of installments as needed. All payments are non-refundable, except as required by law.
    <br/>
    <h4>5. User Conduct and Responsibilities</h4>
    By using the App, you agree:
    <br/>
    •	To use the App for lawful purposes only.
    <br/>
    •	Not to disrupt or interfere with the App's operations or servers.
    <br/>
    •	Not to impersonate any individual or entity or misrepresent your affiliation with any person or organization.
    You are solely responsible for any activities carried out under your account, including maintaining the confidentiality of your login information.
    <h4>6. Content and Intellectual Property</h4>
    All content available on the App, including but not limited to digital notes, videos, and learning materials, is provided solely as a complementary feature for students who have registered for physical classes. The digital content is not sold separately from the physical course registration.
    <h4>7. User-Generated Content</h4>
    If you upload, post, or submit any content to the App (such as reviews or comments), you grant Bright Future Institute a worldwide, non-exclusive, royalty-free license to use, reproduce, display, and distribute that content in connection with the App and its services.
    <h4>8. Privacy Policy</h4>
    Your use of the App is also governed by our Privacy Policy, which describes how we collect, use, and protect your personal data. Please read the Privacy Policy carefully to understand our practices.
    <h4>9. Limitation of Liability</h4>
    To the fullest extent permitted by law, Bright Future Institute will not be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to loss of profits, revenue, data, or use, arising from your use of the App or Services.
    <h4>10. Termination</h4>
    We reserve the right to terminate or suspend your account or access to the App without notice if we believe you have violated these Terms or if your use of the App harms other users or violates any applicable laws or regulations.
    <h4>11. Refund Policy</h4>
    Refunds for course subscriptions or add-ons are generally not allowed, except under specific circumstances. To request a refund, you must submit a formal request to the App administration ("Admin") through the appropriate contact channels within the App. Each request will be reviewed on a case-by-case basis, and refunds may be granted at the sole discretion of Bright Future Institute if the request is deemed valid (e.g., technical issues, course cancellations, etc.). 
    <h4>12. Modifications to the Services</h4>
    Bright Future Institute reserves the right to modify or discontinue any part of the App or Services at any time without prior notice. We are not liable for any changes, suspension, or discontinuation of the App.
    <h4>13. Contact Information</h4>
    If you have any questions about these Terms, please contact us at:
    <br/>
    •	Email: info@brightfuturekw.net
    <br/>
    •	Phone: (+965 96771010), (+965 96777047)
    <br/>
    •	Address: Kuwait, Sabah Al-Salem, Block 2, Street 208, Building 199, 1st Floor
    </div>
  )
}

export default index