import { axios } from "../../../lib/axios";
import { API_ROUTES } from "../../../utils/api_routes";
import { Pagination } from "../../common/interface";
import {
	InstructorListResponse,
	UniversityListResponse,
	AddInstructorPayload,
	AddInstructorResponse,
	RemoveInstructorPayload,
	RemoveInstructorRespose,
	GetInstructorResponse,
	Filter
} from "./interface";

export const list = (query: Pagination,filter:Filter): Promise<InstructorListResponse> => {

	const {university_id,sort,status}=filter

	return axios.get(API_ROUTES.INSTRUCTOR.GETLIST, { params: {...query,...(university_id!==-1&&{university_id}),...(sort!==-1&&{sort}),...(status!==-1&&{status})} });
};
export const instructorListWithoutPagination=():Promise<InstructorListResponse>=>{
	return axios.get(API_ROUTES.INSTRUCTOR.GETLIST);
}

export const getAllUniversities = (): Promise<UniversityListResponse> => {
	return axios.get(API_ROUTES.COMMON.GETALLUNIVERSITIES);
}
export const getAllInstructorUniversity=(id:number):Promise<InstructorListResponse>=>{
	return axios.get(`${API_ROUTES.INSTRUCTOR.GETLIST}`, {params:{university_id:id}})
}

export const addInstructor = (query: AddInstructorPayload): Promise<AddInstructorResponse> => {
	return axios.post(API_ROUTES.INSTRUCTOR.ADDINSTRUCTOR, query);
}

export const removeInstructor = (query: RemoveInstructorPayload): Promise<RemoveInstructorRespose> => {
	return axios.delete(`${API_ROUTES.INSTRUCTOR.REMOVEINSTRUCTOR}${query.id}`);
}

export const getInstructor = (query: RemoveInstructorPayload): Promise<GetInstructorResponse> => {
	return axios.get(`${API_ROUTES.INSTRUCTOR.GETINSTRUCTOR}${query.id}`);
}
export const editInstructor = (id: Number, query: AddInstructorPayload): Promise<RemoveInstructorRespose> => {
	return axios.post(`${API_ROUTES.INSTRUCTOR.EDITINSTRUCTOR}${id}`, query);
}