import { axios } from "../../../lib/axios";
import { API_ROUTES } from "../../../utils/api_routes";
import { RemoveInstructorRespose } from "../../instructor/api/interface";
import { LoginResponse, LoginAdminPayload, ChangePasswordPayload } from "./interface";

export const login = (payload: LoginAdminPayload): Promise<LoginResponse> => {
	return axios.post(API_ROUTES.AUTH.LOGIN, payload);
};

export const changePassword=(payload:ChangePasswordPayload):Promise<RemoveInstructorRespose>=>{
	return axios.post(API_ROUTES.AUTH.CHANGE_PASSWORD, payload)

}