import React, { useEffect, useState } from "react";
import moment from "moment";
import { Button, Dropdown, Form, InputGroup, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisH,
  faPlus,
  faEdit,
  faTrash,
  faInfoCircle,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import Layout from "../../../components/Layout";
import commonStyles from "../../common/styles/common.module.css";
import { removeStudent, studentList } from "../api";
import { PaginationInterface } from "../../../components/Pagination/interfaces";
import { Student } from "../api/interface";
import { Avatar } from "../../../assets/images";
import Pagination from "../../../components/Pagination";
import { MESSAGES } from "../../../utils/messages";
import { STUDENT } from "../../../utils/student";
import { ROUTE_CONSTANTS } from "../../../routes/constants";
import { APP_ROUTES } from "../../../utils/routes";
import { useAppSelector } from "../../../redux/hooks";
//import { PaginationInterface } from "../../../components/Pagination/interfaces";
//import { studentList } from "../api";

const StudentList = () => {
  const [pagination, setPagination] = useState<PaginationInterface>({
    page: 1,
    limit: 10,
    count: 0,
    totalPages: 0,
  });
  const navigate = useNavigate();

  //const [instructors, setInstructors] = useState<Instructor[]>([]);
  const [students, setStudents] = useState<Student[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [search, setSearch] = useState<string>("");
  const [searchValue, setSearchValue] = useState<string>("");
  const searchText = useAppSelector((state) => state.searchReducer.text);

  useEffect(() => {
    setSearchValue(searchText as string)
  }, [searchText])

  const refresh = () => {
    setLoading(true);
    const query = {
      per_page: pagination.limit,
      page: pagination.page,
      name:searchValue
    };

    studentList(query).then((res) => {
      const {
        paginate: { total, last_page },
        data,
      } = res;
      setPagination({ ...pagination, count: total, totalPages: last_page });
      setStudents(data);
      setLoading(false);
    });
  };

  const handleRemoveStudent = (id: Number) => {
    toast.promise(removeStudent({ id: id }), {
      pending: {
        render() {
          return MESSAGES.STUDENT.REMOVING;
        },
      },
      success: {
        render({}) {
          refresh();
          return MESSAGES.STUDENT.REMOVED;
        },
      },
      error: {
        render() {
          return MESSAGES.STUDENT.NOTREMOVED;
        },
      },
    });
  };
  const checkInstitute = (item: Student) => {
    if (item.student.student_type === STUDENT.UNIVERSITY) {
      return item.student.universityDepartment?.name;
    } else if (item.student.student_type === STUDENT.SCHOOL) {
      return item.student.school?.name;
    } else {
      return item.student.trainingField?.name;
    }
  };

  useEffect(() => {
    refresh();
  }, [pagination.page, searchValue]);

  return (
    <Layout>
      <div className={commonStyles["outtre-pad"]}>
        <div className={commonStyles["heaing-filter"]}>
          <h3>Student Management</h3>
          {/* <div className={commonStyles["filter-left"]}>
            <span>
              Filter:{" "}
              <select>
                <option>Location</option>
                <option>This Week</option>
              </select>
            </span>
            <span>
              Filter:{" "}
              <select>
                <option>Price</option>
                <option>This Week</option>
              </select>
            </span>
            <span>
              Filter:{" "}
              <select>
                <option>Department</option>
                <option>This Week</option>
              </select>
            </span>
            <span>
              Filter:{" "}
              <select>
                <option>This Week</option>
                <option>This Week</option>
              </select>
            </span>
          </div> */}
        </div>

        {!loading ? (
          <div className={`light-theme-table ${commonStyles["table-data"]}`}>
            {/* <div className={`${commonStyles["property-head"]} pb-1`}>
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <InputGroup className={`${commonStyles["search-bar"]} mb-3`}>
                  <Form.Control
                    placeholder="Search Name"
                    type="text"
                    value={search}
                    aria-label="Search"
                    onChange={(e) => setSearch(e.target.value)}
                    aria-describedby="basic-addon2"
                  />
                  <Button
                    onClick={handleSearch}
                    variant="outline-secondary"
                    id="button-addon2"
                  >
                    <FontAwesomeIcon icon={faSearch} />
                  </Button>
                </InputGroup>
                <li className="nav-item" role="presentation">
                  <button
									className={`nav-link ${commonStyles.activeTab}`}
									id="pills-home-tab"
									data-bs-toggle="pill"
									data-bs-target="#pills-home"
									type="button"
									role="tab"
									aria-controls="pills-home"
									aria-selected="true"
								>
                  All
								</button> 
                </li>
              </ul>
              <div className={commonStyles["filter-pro"]}>
                <button
                  onClick={() => navigate(APP_ROUTES.STUDENTS_ADD)}
                  className={commonStyles["add-lead"]}
                >
                  <FontAwesomeIcon icon={faPlus} /> Add New Student
                </button>
              </div>
            </div> */}
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Department</th>
                      <th className="text-center">Email</th>
                      <th className="text-center">Mobile No</th>
                      <th>Date</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {students.map((item) => (
                      <tr key={item.id}>
                        <td>
                          <img
                            src={item.avatar ? item.avatar : Avatar}
                            height={40}
                            width={40}
                            alt="avatar"
                          />
                          {item.full_name.trim()}
                        </td>
                        <td>{checkInstitute(item)}</td>
                        <td className="text-center">{item.email}</td>
                        <td className="text-center">
                          +{item.country_code} {item.mobile}
                        </td>
                        <td>
                          {moment(item.created_at).format("MMM DD, YYYY")}
                        </td>
                        {/* <td>
												<span className={commonStyles["bl-shd"]}>
													{"View Courses"}
												</span>
											</td> */}
                        <td>
                          <Dropdown
                            drop={"down-centered"}
                            className={commonStyles.tddropdown}
                          >
                            <Dropdown.Toggle>
                              <FontAwesomeIcon icon={faEllipsisH} />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={() =>
                                  navigate(
                                    `${APP_ROUTES.STUDENTS_LIST}/edit/${item.id}`
                                  )
                                }
                              >
                                <FontAwesomeIcon
                                  className={commonStyles.iconmargin}
                                  icon={faEdit}
                                  size="xs"
                                />
                                Edit
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => handleRemoveStudent(item.id)}
                              >
                                <FontAwesomeIcon
                                  className={commonStyles.iconmargin}
                                  size="xs"
                                  icon={faTrash}
                                />
                                Remove
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() =>
                                  navigate(
                                    `${ROUTE_CONSTANTS.STUDENTS}/${item.id}`
                                  )
                                }
                              >
                                <FontAwesomeIcon
                                  size="xs"
                                  className={commonStyles.iconmargin}
                                  icon={faInfoCircle}
                                />
                                View More
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" role="status"></Spinner>
          </div>
        )}
      </div>
      <Pagination
        paginationState={pagination}
        setPaginationState={setPagination}
      />
    </Layout>
  );
};

export default StudentList;
