import React, { useEffect, useState } from "react"
import Layout from "../../../../../../components/Layout"
import { useParams } from "react-router-dom"
import { getParticularChapter } from "../../api";
import {  ParticularChapterResponse } from "../../api/interface";

const ChapterInfo = () => {
	const {id}=useParams();
	const [chapterInfo, setChapterInfo]=useState<ParticularChapterResponse>()
	useEffect(() => {
		getParticularChapter(Number(id)).then((res)=>{
			setChapterInfo(res);

		})
	}, [])
    

	return (
		<Layout>
			
			{chapterInfo?.data?.videos.map((video, id)=> <div ><iframe src={`https://iframe.mediadelivery.net/embed/269356/${video.video_url}?autoplay=true&loop=true&muted=false&preload=true&responsive=true`}
				 loading="lazy" style={{border:0,height:"30%",width:"30%"}} allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;" allowFullScreen={true}></iframe></div>
			)}
    
		</Layout>
	)
}

export default ChapterInfo