import React from "react"
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { GraphProps } from "./interface";

const PieGraph = ({data}:GraphProps) => {
	const series= data
	const option: ApexOptions= {
		tooltip:{y: {
			formatter: function (val) {
				return val + " KD"
			}
		}},
		labels: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
		responsive: [{
			breakpoint: 480,
			options: {
				chart: {
					width: 300
				},
				legend: {
					position: "bottom"
				}
                  
			}
		}]}
    
	return (
		<div> <ReactApexChart  options={option} series={series} height={700} type="pie" width={500}/></div>
	)
}

export default PieGraph