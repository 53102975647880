import React, { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { Dropdown, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	
	faEllipsisH,
	faInfoCircle,

} from "@fortawesome/free-solid-svg-icons";
// import { toast } from "react-toastify";

import { courseList } from "../api";
import { CourseDeatails } from "../api/interface";
import Layout from "../../../../../components/Layout";
import commonStyles from "../../../../common/styles/common.module.css";
import Pagination from "../../../../../components/Pagination";
import { PaginationInterface } from "../../../../../components/Pagination/interfaces";
import { PriceModal } from "../modals";
// import { courseModal } from "../../../../../redux/reducers/course";
// import { useAppDispatch } from "../../../../../redux/hooks";
// import { MESSAGES } from "../../../../../utils/messages";
import { ROUTE_CONSTANTS } from "../../../../../routes/constants";
// import { INSTRUCTOR } from "../../../../../utils/instructor";
import AddorEdit from "../modals/AddorEdit";

const CourseList = () => {
	// const dispatch = useAppDispatch();
	const { id } = useParams();

	const [course, setCourse] = useState<CourseDeatails[]>([]);
	const [priceModal, setPriceModal] = useState<boolean>(false);
	const [showModal, setShowModal] = useState<boolean>(false);
	const [loading, setLoading]=useState<boolean>(true)

	const navigate = useNavigate();
	// const [showModal, setShowModal] = useState<boolean>(false)
	const [pagination, setPagination] = useState<PaginationInterface>({
		page: 1,
		limit: 10,
		count: 0,
		totalPages: 0,
	});
	// const addNewCourse = () => {
	// 	dispatch(courseModal({ id: 0, editOrAdd: INSTRUCTOR.REDUX.ADD }));
	// 	setShowModal(true);
	// };

	const refresh = () => {
		setLoading(true);
		const query = {
			per_page: pagination.limit,
			page: pagination.page,
		};

		courseList(query, Number(id)).then((res) => {
			const {
				paginate: { total, last_page },
				data,
			} = res;
			setPagination({ ...pagination, count: total, totalPages: last_page });
			setCourse(data);
			setLoading(false);
		});
	};

	// const handleRemoveCourse = (id: number) => {
	// 	toast.promise(removeCourse(id), {
	// 		pending: {
	// 			render() {
	// 				return MESSAGES.COURSE.REMOVE;
	// 			},
	// 		},
	// 		success: {
	// 			render({}) {
	// 				refresh();

	// 				return MESSAGES.COURSE.REMOVED;
	// 			},
	// 		},
	// 		error: {
	// 			render() {
	// 				return MESSAGES.COURSE.NOTREMOVE;
	// 			},
	// 		},
	// 	});
	// };
	const handleClose = () => {
		setPriceModal(false);
	};
	const handleAddClose = () => {
		setShowModal(false);
	};

	// const approveCourse = (id: number) => {
	// 	dispatch(courseModal({ id }));
	// 	setPriceModal(true);
	// };

	useEffect(() => {
		refresh();
	}, [pagination.page]);

	useEffect(() => {
		if(showModal===false)
			refresh();
	}, [priceModal, showModal]);

	return (
		<Layout>
			<div className={commonStyles["outtre-pad"]}>
				<div className={commonStyles["heaing-filter"]}>
					<h3>Courses</h3>
					{/* <div className={commonStyles["filter-left"]}>
					<span>Filter: <select>
						<option>Location</option>
						<option>This Week</option>
					</select></span>
					<span>Filter: <select>
						<option>Price</option>
						<option>This Week</option>
					</select></span>
					<span>Filter: <select>
						<option>Department</option>
						<option>This Week</option>
					</select></span>
					<span>Filter: <select>
						<option>This Week</option>
						<option>This Week</option>
					</select></span>
				</div> */}
				</div>
				{!loading?<div className={commonStyles["table-data"]}>
					<div className={`${commonStyles["property-head"]} pb-1`}>
						<ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
							<li className="nav-item" role="presentation">
								{/* <button
									className={`nav-link ${commonStyles.activeTab}`}
									id="pills-home-tab"
									data-bs-toggle="pill"
									data-bs-target="#pills-home"
									type="button"
									role="tab"
									aria-controls="pills-home"
									aria-selected="true"
								>
                  All
								</button> */}
							</li>
						</ul>

						{/* <div className={commonStyles["filter-pro"]}>
							<button
								onClick={addNewCourse}
								className={commonStyles["add-lead"]}
							>
								<FontAwesomeIcon icon={faPlus} /> Add New Course
							</button>
						</div> */}
					</div>
					<div className="tab-content" id="pills-tabContent">
						<div
							className="tab-pane fade show active"
							id="pills-home"
							role="tabpanel"
							aria-labelledby="pills-home-tab"
						>
							<table>
								<thead>
									<tr>
										<th>Name</th>
										<th className="text-center">Instructor</th>
										<th>Published</th>
										<th className="text-center">Created At</th>
										<th >Full Price</th>
										<th className="text-center">University</th>
										<th>Status</th>
										<th className="text-center">Student Count</th>
										<th className="text-center">Action</th>
									</tr>
								</thead>
								<tbody>
									{course.map((item) => {
										return (
											<tr key={item.id}>
												<td>{item.name}</td>
												<td className="text-center">{item.instructor.full_name}</td>
												<td>
													{item.isPublished ? "Published" : "Not Published"}{" "}
												</td>
												<td className="text-center">
													{moment(item.created_at).format("MMM DD, YYYY")}
												</td>
												<td className="text-center">
                          ₹{item.full_price.toString()}
												</td>
												<td className="text-center">{item.university?.name}</td>
												<td>
													<span
														className={
															!(
																item.is_approved_by_admin === 1 &&
                                item.is_approved_by_instructor === 1
															)
																? `${commonStyles["bl-shd"]} ${commonStyles.red}`
																: `${commonStyles["bl-shd"]} ${commonStyles.grn}`
														}
													>
														{item.is_approved_by_admin === 1 &&
                            item.is_approved_by_instructor === 1
															? "Approved"
															: "Not Approved"}
													</span>
												</td>
												<td className="text-center">{item.purchasedStudentsCount}</td>

												<td className="text-center">
													<Dropdown
														drop={"down-centered"}
														className={commonStyles.tddropdown}
													>
														<Dropdown.Toggle>
															<a className="me-2"></a>
															<FontAwesomeIcon icon={faEllipsisH} />
														</Dropdown.Toggle>

														<Dropdown.Menu>
															<Dropdown.Item
																onClick={() =>
																// console.log(`/instructors${ROUTE_CONSTANTS.COURSES}/chapter/${item.id}`)
																	
																	navigate(
																		`${ROUTE_CONSTANTS.INSTRUCTORS_CHAPTERS}/${item.id}`
																	)
																}
															>
																<FontAwesomeIcon
																	size="xs"
																	className={commonStyles.iconmargin}
																	icon={faInfoCircle}
																/>
                                View More
															</Dropdown.Item>
															<Dropdown.Item
																onClick={() =>
																// console.log(`/instructors${ROUTE_CONSTANTS.COURSES}/chapter/${item.id}`)
																	
																	navigate(
																		`${ROUTE_CONSTANTS.INSTRUCTORS_COURSES_STUDENTLIST}/${item.id}`
																	)
																}
															>
																<FontAwesomeIcon
																	size="xs"
																	className={commonStyles.iconmargin}
																	icon={faInfoCircle}
																/>
                                Students List
															</Dropdown.Item>
															{/* <Dropdown.Item
																onClick={() => {
																	dispatch(
																		courseModal({
																			id: item.id,
																			editOrAdd: INSTRUCTOR.REDUX.EDIT,
																		})
																	);
																	setShowModal(true);
																}}
															>
																<FontAwesomeIcon
																	onClick={() => {
																		dispatch(
																			courseModal({
																				id: item.id,
																				editOrAdd: INSTRUCTOR.REDUX.EDIT,
																			})
																		);
																		setShowModal(true);
																	}}
																	size="xs"
																	className={commonStyles.iconmargin}
																	icon={faEdit}
																/>
                                Edit
															</Dropdown.Item>

															<Dropdown.Item
																onClick={() => approveCourse(item.id)}
															>
																<FontAwesomeIcon
																	size="xs"
																	className={commonStyles.iconmargin}
																	icon={faCheck}
																/>
                                Approve
															</Dropdown.Item>
															<Dropdown.Item
																onClick={() => handleRemoveCourse(item.id)}
															>
																<FontAwesomeIcon
																	size="xs"
																	className={commonStyles.iconmargin}
																	icon={faTrash}
																/>
                                Remove
															</Dropdown.Item> */}
														</Dropdown.Menu>
													</Dropdown>
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
					</div>
				</div>: <div className="d-flex justify-content-center">
					<Spinner animation="border" role="status">
					</Spinner>
				</div>}
			</div>

			<Pagination
				paginationState={pagination}
				setPaginationState={setPagination}
			/>
			<PriceModal handleClose={handleClose} showModal={priceModal} />
			<AddorEdit showModal={showModal} handleClose={handleAddClose} />
		</Layout>
	);
};
export default CourseList;
