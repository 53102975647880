import React from "react";
// import { useFormik } from "formik";
// import { toast } from "react-toastify";
import { Button, Form, Modal } from "react-bootstrap";

import { AddUniversityProp } from "./interface";
// import { useAppSelector } from "../../../redux/hooks";
// import { INSTRUCTOR } from "../../../utils/instructor";
import styles from "../../instructor/styles/instructor.module.css";
// import commonstyles from "../../instructor/styles/common.module.css";
// import { addUniversity, editUniversity, getUniversity } from "../api";
// import DepartmentDetailsSchema from "../validations";
import { UNIVERSITY } from "../../../utils/university";
// import { MESSAGES } from "../../../utils/messages";

const AddOrEditSemester = ({ showModal, handleClose }: AddUniversityProp) => {
	// const selectedUniversity = useAppSelector((state) => state.universityReducer);

	// useEffect(() => {
	// 	if (selectedUniversity.editOrAdd === INSTRUCTOR.REDUX.EDIT) {
	// 		getUniversity(Number(selectedUniversity.id)).then((res) => {
	// 			const {
	// 				data: { name },
	// 			} = res;
	// 			universityDetailsFormik.setFieldValue(UNIVERSITY.FORMIK.NAME, name);
	// 		});
	// 	} else {
	// 		universityDetailsFormik.resetForm();
	// 	}
	// }, [showModal]);

	// const universityDetailsFormik = useFormik({
	// 	initialValues: {
	// 		name: "",
	// 	},
	// 	enableReinitialize: true,
	// 	onSubmit: (values) => {
	// 		toast.promise(
	// 			selectedUniversity.editOrAdd === INSTRUCTOR.REDUX.ADD
	// 				? addUniversity(values.name)
	// 				: editUniversity(selectedUniversity.id, values.name),
	// 			{
	// 				pending: {
	// 					render() {
	// 						return selectedUniversity.editOrAdd === INSTRUCTOR.REDUX.ADD
	// 							? MESSAGES.UNIVERSITY.ADD
	// 							: MESSAGES.UNIVERSITY.EDIT;
	// 					},
	// 				},
	// 				success: {
	// 					render() {
	// 						universityDetailsFormik.resetForm();
	// 						handleClose();

	// 						return selectedUniversity.editOrAdd === INSTRUCTOR.REDUX.ADD
	// 							? MESSAGES.UNIVERSITY.SUCCESS
	// 							: MESSAGES.UNIVERSITY.EDITED;
	// 					},
	// 				},
	// 				error: {
	// 					render() {
	// 						return selectedUniversity.editOrAdd === INSTRUCTOR.REDUX.ADD
	// 							? MESSAGES.UNIVERSITY.INVALID
	// 							: MESSAGES.UNIVERSITY.NOTEDITED;
	// 					},
	// 				},
	// 			}
	// 		);
	// 	},

	// 	validationSchema: DepartmentDetailsSchema,
	// });
	// const handleAddDepartment = (e: React.SyntheticEvent<HTMLFormElement>) => {
	// 	e.preventDefault();

	// 	universityDetailsFormik.submitForm();
	// };

	return (
		<Modal
			show={showModal}
			onHide={() => {
				handleClose();
			}}
		>
			<div>
				<Modal.Header closeButton>
					<Modal.Title>
						{/* {selectedUniversity.editOrAdd === INSTRUCTOR.REDUX.EDIT
							? "Edit University"
							: "Add New University"} */}
                            Add Semester Date
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form >
						<div className={styles.fieldStyle}>
							<Form.Group className="from-group" controlId="formFirstName">
								<Form.Label>Start Date</Form.Label>
								<Form.Control
									type="date"
									placeholder="Name"
									
									name={UNIVERSITY.FORMIK.NAME}
									
								/>
							</Form.Group>
							{/* {universityDetailsFormik.errors.name &&
              universityDetailsFormik.touched.name ? (
									<span className={commonstyles.error}>
										{universityDetailsFormik.errors.name}
									</span>
								) : null} */}
							<Form.Group className="from-group" controlId="formFirstName">
								<Form.Label>End Date</Form.Label>
								<Form.Control
									type="date"
									
									
									name="name"
									
								/>
							</Form.Group>
						</div>
						<Button className={`${styles.buttonss} mt-3`} type="submit">
							{/* {selectedUniversity.editOrAdd === INSTRUCTOR.REDUX.ADD
								? INSTRUCTOR.ADD
								: INSTRUCTOR.EDIT} */}
                                Add
						</Button>
					</Form>
				</Modal.Body>
			</div>
		</Modal>
	);
};

export default AddOrEditSemester;
