import React from "react";
import { Button, Form, Modal } from "react-bootstrap";

import { ChangeStatusProp } from "./interface";
import styles from "../../instructor/styles/instructor.module.css";
import { toast } from "react-toastify";
import { profileStatus } from "../api";
import { STUDENT } from "../../../utils/student";
import { MESSAGES } from "../../../utils/messages";

const ProfileStatus = ({
	showModal,
	handleClose,
	status,
	id,
}: ChangeStatusProp) => {
	const handleChangeStatus = () => {
		toast.promise(
			status === STUDENT.ACTIVATE ? profileStatus(id, 1) : profileStatus(id, 2),
			{
				pending: {
					render() {
						return status === STUDENT.ACTIVATE
							? MESSAGES.STUDENT.ACTIVATE
							: MESSAGES.STUDENT.DEACTIVATE;
					},
				},
				success: {
					render() {
						handleClose();

						return status === STUDENT.ACTIVATE
							? MESSAGES.STUDENT.ACTIVATED
							: MESSAGES.STUDENT.DEACTIVATED;
					},
				},
				error: {
					render() {
						return status === STUDENT.ACTIVATE
							? MESSAGES.STUDENT.NOTACTIVATED
							: MESSAGES.STUDENT.NOTDEACTIVATED;
					},
				},
			}
		);
	};
	return (
		<Modal
			show={showModal}
			onHide={() => {
				handleClose();
			}}
		>
			<div className={styles["add-lead-from"]}>
				<Modal.Header closeButton>
					<Modal.Title>Change Profile Status</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<h5>Are you sure you want to {status} Profile?</h5>
					<Form onSubmit={handleChangeStatus}>
						<div className={styles.spacebetween}>
							<Button
								onClick={handleChangeStatus}
								className={`${styles.statusbuttons} mt-3`}
							>
                Yes
							</Button>
							<Button
								onClick={handleClose}
								className={`${styles.statusbuttons} mt-3`}
							>
                No
							</Button>
						</div>
					</Form>
				</Modal.Body>
			</div>
		</Modal>
	);
};

export default ProfileStatus;
