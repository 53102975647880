import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import "./App.css";
import AppRoutes from "./routes";
import "react-toastify/dist/ReactToastify.css";
import { useAppDispatch } from "./redux/hooks";
import { GetProfileResponse } from "./components/api/interface";
import { getProfile } from "./components/api";
import { authenticateUser } from "./redux/reducers/auth";
import storage from "./utils/storage";

function App() {
	const dispatch = useAppDispatch();

	useEffect(() => {
		getProfile().then((res: GetProfileResponse) => {
			const {
				user: { full_name, avatar },
			} = res;
			dispatch(authenticateUser({ name: full_name, avatar }));
		}).catch((error:any)=>{
			if(error.status===401)
			{
				storage.clearToken();
			}
		});
	}, []);

	return (
		<Router>
			<AppRoutes />
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="light"
			/>
		</Router>
	);
}

export default App;
