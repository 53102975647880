import React from 'react'

const index = () => {
  return (
    <div style={{padding:"20px"}}>
        <h2 style={{textAlign:"center"}}>Privacy Policy</h2>
        Last Updated: [9/6/2024]
<p>At Bright Future Institute, your privacy is of utmost importance to us. This Privacy Policy
describes how we collect, use, and protect your personal information when you use our mobile
application, website, and any services provided through the platform. By using the App, you agree
to the collection and use of information as outlined in this Privacy Policy.</p>
<h4 >1. Information We Collect</h4>
<p>When you use the Bright Future Institute App, we may collect the following types of information:</p>
<h5>1.1 Personal Information</h5>
<p>• Account Registration: Upon registration, we collect information such as your phone number and
specific details based on the type of user:</p>
<p>o University Students: We ask for your department.</p>
<p>o School Students: We ask for your school level.</p>
<p>o General Users: We ask for your training field.</p>
<p>• Payment Information: Payments for subscriptions and add-ons are processed externally through
MyFatoorah. We do not store payment information, but we may retain details about transactions,
including payment confirmation and the services purchased.</p>
<h5>1.2 Usage Data</h5>
<p>We may collect non-personally identifiable information about your interaction with the App, such as:</p>
<p>• Device Information: Details about your mobile device, including the type of device, operating
system, and unique device identifiers.</p>
<p>• Log Data: Information such as IP addresses, browser types, the pages of our App you visit, and the
time spent on those pages.</p>
<h5>1.3 Cookies</h5>
<p>We use cookies to collect data to help us improve your experience on our App. Cookies are small files
stored on your device that allow us to track certain information about your usage. You can disable cookies
in your browser settings, though this may affect the functionality of the App.</p>
<h4>2. How We Use Your Information</h4>
<p>Bright Future Institute uses your personal information to:</p>
<p>• Provide and maintain services: To facilitate access to your subscriptions and ensure the delivery
of services, including managing class registrations and access to supplementary digital content.</p>
<p>• Communicate with you: We may contact you through email, phone, or within the App to inform
you of updates, respond to inquiries, or provide support.</p>
<p>• Process payments: To manage subscriptions and purchases through our third-party payment
provider, MyFatoorah.</p>
<p>• Improve the App: We use non-personal data, like usage information, to enhance our services,
troubleshoot issues, and develop new features.</p>
<h4>3. Sharing and Disclosure of Information</h4>
<br/>
We respect your privacy, and your personal information will not be sold or rented to third parties. However,
we may share information in the following cases:
<br/>
• Service Providers: We may share information with third-party service providers, such as
MyFatoorah, for payment processing and other essential services.
<br/>
• Legal Requirements: We may disclose your personal information if required to do so by law or if
we believe such action is necessary to comply with legal obligations or protect the rights and safety
of Bright Future Institute, its users, or others.
<br/>
<h4>4. Data Security</h4>
We are committed to protecting your personal information. While we take reasonable steps to safeguard
your data, please note that no method of transmission over the internet or electronic storage is 100%
secure. We use commercially acceptable means to protect your personal information, but we cannot
guarantee absolute security.
<h4>5. Third-Party Links</h4>
Our App contains links only to the MyFatoorah payment provider for processing payments. We are not
responsible for the privacy practices or content of MyFatoorah. We recommend that you review their
privacy policy before providing personal information.
<h4>6. Changes to This Privacy Policy</h4>
Bright Future Institute may update this Privacy Policy from time to time. Any changes will be posted within
the App, and the “Last Updated” date at the top will be modified accordingly. We encourage you to review
this Privacy Policy periodically to stay informed about how we are protecting your information.
<h4>7. Contact Us</h4>
If you have any questions about this Privacy Policy or the practices of the Bright Future Institute, please
contact us at:
<br/>
• Email: info@brightfuturekw.net
<br/>
• Phone: (+965 96771010), (+965 96777047)
    </div>
  )
}

export default index