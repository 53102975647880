import * as Yup from "yup";
import { YUP } from "../../../utils/validations";

const DepartmentDetailsSchema = Yup.object().shape({
	name: Yup
		.string()
		.required(YUP.REQUIRED)
});
const StorySchema = Yup.object().shape({
	Title:Yup.string().required(),
	image: Yup.mixed().required()
});


export  {DepartmentDetailsSchema,StorySchema};