import React from "react";
import ReactPaginate from "react-paginate";
import { faAngleDoubleLeft, faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


import styles from "./style.module.css"
import { PaginationPropInterface } from "./interfaces";

const Pagination = ({ paginationState, setPaginationState }: PaginationPropInterface) => {
	const handlePageClick = (selectedPage: { selected: number }) => {
		setPaginationState({ ...paginationState, page: selectedPage.selected + 1 })
	}

	return (
		<ReactPaginate
			className={` darkPagination ${styles.paginationContainer}`}
			activeClassName={styles.selected}
			pageLinkClassName={styles.pageLink}
			breakLabel="..."
			nextLabel={<FontAwesomeIcon size="xs" icon={faAngleDoubleRight} />}
			pageClassName={styles.page}
			onPageChange={handlePageClick}
			pageCount={paginationState?.totalPages}
			previousClassName={styles.paginateButton}
			nextClassName={styles.paginateButton}
			previousLinkClassName={styles.paginateLink}
			nextLinkClassName={styles.paginateLink}
			previousLabel={<FontAwesomeIcon size="xs" icon={faAngleDoubleLeft} />}
			renderOnZeroPageCount={null}
		/>
	)
}

export default Pagination