import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface Theme {
	theme: "dark"|"light"
}

const initialState: Theme = {
	theme: "light"
}

export const themeSlice = createSlice({
	name: "theme",
	initialState,
	reducers: {
		changeTheme: (state, action: PayloadAction<Theme>) => {
			state.theme = action.payload.theme
			

		},
	},
});

export const { changeTheme } = themeSlice.actions;
export const themeSelector = (state: RootState) => state.themeReducer;

export default themeSlice.reducer;