import React from "react";
import { Navigate } from "react-router-dom";
import Login from "../features/auth/routes/login";
import PrivacyPolicy from "../features/PrivacyPolicy";
import TermsAndConditions from "../features/TermsAndCondition"

import { APP_ROUTES } from "../utils/routes";

export const publicRoutes = [
	{ path: APP_ROUTES.LOGIN, element: <Login /> },
	{ path: "/privacypolicy", element: <PrivacyPolicy/> },
	 { path: "/termsandconditions", element: <TermsAndConditions/>},
	{ path: "*", element: <Navigate replace to="/" /> },
];


