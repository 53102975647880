import React from "react"
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { GraphProps } from "./interface";

const ColumnGraph = ({data, name}:GraphProps) => {
	const series= [{
		name: name,
		data: data
	}]
	const options:ApexOptions={
		chart: {
			type: "bar",
			height: 350,
			width:300
		},
		plotOptions: {
			bar: {
				horizontal: false,
				columnWidth: "55%",
            
			},
		},
		dataLabels: {
			enabled: false
		},
		stroke: {
			show: true,
			width: 2,
			colors: ["transparent"]
		},
		xaxis: {
			categories: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
		},
		yaxis: {
			stepSize:1,
			title: {
				text: "Count"
			}
		},
		fill: {
			opacity: 1
		},
		tooltip: {
			y: {
				formatter: function (val) {
					return val + " Course"
				}
			}
		},
		title:{
			text:name,
			floating: true,
			offsetY: 500,
		}
	}
    
    
	return (
		<div> <ReactApexChart  options={options} series={series}  type="bar" height={500} width={600} /></div>
	)
}

export default ColumnGraph