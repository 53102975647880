import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface Addon {
    id:Number,
	editOrAdd?: String
}

const initialState: Addon = 
	{
		id:0,
		editOrAdd: "Add"
	}


export const addonSlice = createSlice({
	name: "addon",
	initialState,
	reducers: {
		addonModal: (state, action: PayloadAction<Addon>) => {
			
			state.id=action.payload.id
			state.editOrAdd = action.payload.editOrAdd

		},
	},
});

export const { addonModal } = addonSlice.actions;
export const addonSelector = (state: RootState) => state.addonReducer;

export default addonSlice.reducer;