import React, { useEffect } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { Button, Form, Modal } from "react-bootstrap";

import { AddUniversityProp } from "./interface";
import { useAppSelector } from "../../../redux/hooks";
import { INSTRUCTOR } from "../../../utils/instructor";
import styles from "../../instructor/styles/instructor.module.css";
import commonstyles from "../../instructor/styles/common.module.css";
import { addUniversity, editUniversity, getUniversity } from "../api";
import {DepartmentDetailsSchema} from "../validations";
import { UNIVERSITY } from "../../../utils/university";
import { MESSAGES } from "../../../utils/messages";
import { uploadImage } from "../../common/utils";

const AddOrEdit = ({ showModal, handleClose }: AddUniversityProp) => {
	const selectedUniversity = useAppSelector((state) => state.universityReducer);

	useEffect(() => {
		if (selectedUniversity.editOrAdd === INSTRUCTOR.REDUX.EDIT) {
			getUniversity(Number(selectedUniversity.id)).then((res) => {
				const {
					data: { name },
				} = res;
				universityDetailsFormik.setFieldValue(UNIVERSITY.FORMIK.NAME, name);
			});
		} else {
			universityDetailsFormik.resetForm();
		}
	}, [showModal]);

	const universityDetailsFormik = useFormik({
		initialValues: {
			name: "",
			image:null
		},
		enableReinitialize: true,
		onSubmit: (values) => {
			uploadImage(values.image).then((res)=>{
				
				toast.promise(
					selectedUniversity.editOrAdd === INSTRUCTOR.REDUX.ADD
						? addUniversity(values.name,res.data[0])
						: editUniversity(selectedUniversity.id, values.name,res.data[0]),
					{
						pending: {
							render() {
								return selectedUniversity.editOrAdd === INSTRUCTOR.REDUX.ADD
									? MESSAGES.UNIVERSITY.ADD
									: MESSAGES.UNIVERSITY.EDIT;
							},
						},
						success: {
							render() {
								universityDetailsFormik.resetForm();
								handleClose();
	
								return selectedUniversity.editOrAdd === INSTRUCTOR.REDUX.ADD
									? MESSAGES.UNIVERSITY.SUCCESS
									: MESSAGES.UNIVERSITY.EDITED;
							},
						},
						error: {
							render() {
								return selectedUniversity.editOrAdd === INSTRUCTOR.REDUX.ADD
									? MESSAGES.UNIVERSITY.INVALID
									: MESSAGES.UNIVERSITY.NOTEDITED;
							},
						},
					}
				);
			})
			
		},

		validationSchema: DepartmentDetailsSchema,
	});
	const handleAddDepartment = (e: React.SyntheticEvent<HTMLFormElement>) => {
		e.preventDefault();

		universityDetailsFormik.submitForm();
	};

	return (
		<Modal
			show={showModal}
			onHide={() => {
				handleClose();
			}}
		>
			<div>
				<Modal.Header closeButton>
					<Modal.Title>
						{selectedUniversity.editOrAdd === INSTRUCTOR.REDUX.EDIT
							? "Edit University"
							: "Add New University"}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form onSubmit={handleAddDepartment}>
						<div className={styles.fieldStyle}>
							<Form.Group className="from-group" controlId="formFirstName">
								<Form.Label>Name</Form.Label>
								<Form.Control
									type="text"
									placeholder="Name"
									value={universityDetailsFormik.values.name}
									name="name"
									onChange={universityDetailsFormik.handleChange}
								/>
							</Form.Group>
							{universityDetailsFormik.errors.name &&
              universityDetailsFormik.touched.name ? (
									<span className={commonstyles.error}>
										{universityDetailsFormik.errors.name}
									</span>
								) : null}
								<Form.Group className="from-group" controlId="formFirstName">
								<Form.Label>Logo</Form.Label>
								<Form.Control
									type="file"
									placeholder="Logo"
									accept="image/*"
									
									name="image"
									onChange={(event:any) => {
										universityDetailsFormik.setFieldValue("image", event.currentTarget.files[0]);
									  }}
								/>
							</Form.Group>
							{/* {universityDetailsFormik.errors.name &&
              universityDetailsFormik.touched.name ? (
									<span className={commonstyles.error}>
										{universityDetailsFormik.errors.name}
									</span>
								) : null} */}
						</div>
						<Button className={`${styles.buttonss} mt-3`} type="submit">
							{selectedUniversity.editOrAdd === INSTRUCTOR.REDUX.ADD
								? INSTRUCTOR.ADD
								: INSTRUCTOR.EDIT}
						</Button>
					</Form>
				</Modal.Body>
			</div>
		</Modal>
	);
};

export default AddOrEdit;
