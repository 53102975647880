import React, { Fragment, useEffect, useState } from "react";
import PhoneInput from "react-phone-number-input";
import { Button, Form } from "react-bootstrap";
import { useFormik } from "formik";
import { parsePhoneNumber } from "react-phone-number-input";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

import {
	DepartmentInterface,
	UniversityInterface,
} from "../../../instructor/api/interface";
import { getAllUniversities } from "../../../instructor/api";
import { INSTRUCTOR } from "../../../../utils/instructor";
import { MESSAGES } from "../../../../utils/messages";
import {
	AddStudentPayload,
	School,
	SchoolListResponse,
	Training,
	TrainingListResponse,
} from "../../api/interface";
import {
	addStudent,
	editStudent,
	getAllSchools,
	getAllTrainingFields,
	getStudent,
} from "../../api";
import { StudentDetailsSchema } from "../../validations";
import styles from "../../../instructor/styles/instructor.module.css";
import commonstyles from "../../../instructor/styles/common.module.css";
import { STUDENT } from "../../../../utils/student";
import { STUDENT_TYPE } from "../../../../utils/constants";
import { APP_ROUTES } from "../../../../utils/routes";
import Layout from "../../../../components/Layout";

const AddOrEdit = () => {
	const [universities, setUniversities] = useState<UniversityInterface[]>([]);
	const [department, setDepartment] = useState<DepartmentInterface[]>();
	const [school, setSchool] = useState<School[]>([]);
	const [trainingFields, setTrainingField] = useState<Training[]>([]);
	const { id } = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		getAllSchools().then((res: SchoolListResponse) => {
			setSchool(res.data);
		});
		getAllTrainingFields().then((res: TrainingListResponse) => {
			setTrainingField(res.data);
		});
		getAllUniversities().then((res) => {
			setUniversities(res?.data);
		});
	}, []);

	useEffect(() => {
		if (id) {
			getStudent({ id: Number(id) }).then((res) => {
				const {
					user: {
						student: {
							student_type,
							school,
							university,
							universityDepartment,
							training_field,
						},
						first_name,
						last_name,
						country_code,
						mobile,
						email,
						no_of_logins,
					},
				} = res;

				studentDetailsFormik.setFieldValue(
					STUDENT.FORMIK.USERTYPE,
					student_type.toLowerCase()
				);
				if (
					res.user.student.student_type.toLowerCase() ===
          STUDENT.STUDENTTYPE.SCHOOL
				) {
					studentDetailsFormik.setFieldValue(
						STUDENT.STUDENTTYPE.SCHOOL,
						school.id
					);
				} else if (
					res.user.student.student_type.toLowerCase() ===
          STUDENT.STUDENTTYPE.UNIVERSITY
				) {
					addDepartments(res.user.student.university.id);
					studentDetailsFormik.setFieldValue(
						STUDENT.STUDENTTYPE.UNIVERSITY,
						university.id
					);
					studentDetailsFormik.setFieldValue(
						INSTRUCTOR.FORMIK.DEPARTMENT,
						universityDepartment.id
					);
				} else {
					studentDetailsFormik.setFieldValue(
						STUDENT.FORMIK.TRAININGFIELD,
						training_field
					);
				}

				studentDetailsFormik.setFieldValue(
					INSTRUCTOR.FORMIK.FIRSTNAME,
					first_name
				);
				studentDetailsFormik.setFieldValue(
					INSTRUCTOR.FORMIK.LASTNAME,
					last_name
				);
				studentDetailsFormik.setFieldValue(
					INSTRUCTOR.FORMIK.PHONENUMBER,
					`${country_code && "+"}${country_code}${mobile}`
				);
				studentDetailsFormik.setFieldValue(INSTRUCTOR.FORMIK.EMAIL, email);
				studentDetailsFormik.setFieldValue(
					STUDENT.FORMIK.DEVICERESTRICTION,
					no_of_logins
				);
			});
		} else {
			studentDetailsFormik.resetForm();
		}
	}, [universities]);

	const studentDetailsFormik = useFormik({
		initialValues: {
			firstName: "",
			lastName: "",
			university: 0,
			department: 0,
			email: "",
			phoneNumber: "",
			password: "",
			userType: "school",
			trainingField: 0,
			school: 0,
			deviceRestriction: "",
		},
		enableReinitialize: true,
		onSubmit: (values) => {
			const countryCode = parsePhoneNumber(
				values.phoneNumber
			)?.countryCallingCode;
			const mobile = parsePhoneNumber(values.phoneNumber)?.nationalNumber;
			const payload: AddStudentPayload = {
				first_name: values.firstName,
				last_name: values.lastName,
				password: values.password,
				email: values.email,
				country_code: countryCode as String,
				mobile: mobile as String,
				no_of_logins: Number(values.deviceRestriction),
			};
			if (values.userType === STUDENT.STUDENTTYPE.SCHOOL) {
				payload.school_id = Number(values.school);
				payload.student_type = STUDENT_TYPE.STUDENT;
			} else if (values.userType === STUDENT.STUDENTTYPE.UNIVERSITY) {
				payload.university_id = values.university;
				payload.university_dep_id = values.department;
				payload.student_type = STUDENT_TYPE.UNIVERSITY;
			} else {
				payload.status = 1;
				payload.training_field = values.trainingField;
				payload.student_type = STUDENT_TYPE.GENERAL;
			}

			toast.promise(
				!id ? addStudent(payload) : editStudent(Number(id), payload),
				{
					pending: {
						render() {
							return !id ? MESSAGES.STUDENT.ADD : MESSAGES.STUDENT.EDIT;
						},
					},
					success: {
						render() {
							studentDetailsFormik.resetForm();
							navigate(APP_ROUTES.STUDENTS_LIST);

							return !id ? MESSAGES.STUDENT.SUCCESS : MESSAGES.STUDENT.EDITED;
						},
					},
					error: {
						render() {
							return !id
								? MESSAGES.STUDENT.INVALID
								: MESSAGES.STUDENT.NOTEDITED;
						},
					},
				}
			);
		},

		validationSchema: StudentDetailsSchema,
	});

	const handleUniversityChange = (
		e: React.ChangeEvent<HTMLInputElement>
	): void => {
		studentDetailsFormik.setFieldValue(
			INSTRUCTOR.FORMIK.UNIVERSITY,
			Number(e.target.value)
		);

		studentDetailsFormik.setFieldValue(INSTRUCTOR.FORMIK.DEPARTMENT, 0);
		addDepartments(Number(e.target.value));
	};

	const handleDepartmentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		studentDetailsFormik.setFieldValue(
			INSTRUCTOR.FORMIK.DEPARTMENT,
			Number(e.target.value)
		);
	};

	const handleAddStudent = (e: React.SyntheticEvent<HTMLFormElement>) => {
		e.preventDefault();

		studentDetailsFormik.submitForm();
	};

	const addDepartments = (id: Number) => {
		const selectedUniversity = universities.filter(
			(uni: UniversityInterface) => uni.id === id
		);
		setDepartment(selectedUniversity[0]?.universityDepartments);
	};

	const handleStudentTypeChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		resetCourse();

		studentDetailsFormik.setFieldValue(
			STUDENT.FORMIK.USERTYPE,
			event.target.value
		);
	};

	const resetCourse = () => {
		studentDetailsFormik.setFieldValue(INSTRUCTOR.FORMIK.DEPARTMENT, 0);
		studentDetailsFormik.setFieldValue(STUDENT.STUDENTTYPE.UNIVERSITY, 0);
		studentDetailsFormik.setFieldValue(STUDENT.STUDENTTYPE.SCHOOL, 0);
		studentDetailsFormik.setFieldValue(STUDENT.FORMIK.TRAININGFIELD, 0);
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		studentDetailsFormik.setFieldValue(
			STUDENT.STUDENTTYPE.SCHOOL,
			Number(e.target.value)
		);
	};
	const handleTrainingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		studentDetailsFormik.setFieldValue(
			STUDENT.FORMIK.TRAININGFIELD,
			Number(e.target.value)
		);
	};

	return (
		<Layout>
			<div
				className={`${styles["add-lead-from-page"]} ${styles["add-lead-from"]}`}
			>
				<h1>{id ? "Edit Student" : "Add New Student"}</h1>

				<Form onSubmit={handleAddStudent}>
					<div className="d-flex justify-content-between">
						<div className="form-check">
							<input
								value={STUDENT.STUDENTTYPE.SCHOOL}
								onChange={handleStudentTypeChange}
								checked={
									studentDetailsFormik.values.userType ===
                  STUDENT.STUDENTTYPE.SCHOOL
								}
								className="form-check-input"
								type="radio"
								name={STUDENT.FORMIK.USERTYPE}
								id={STUDENT.STUDENTTYPE.SCHOOL}
							/>
							<label className="form-check-label" htmlFor="school">
                School
							</label>
						</div>
						<div className="form-check">
							<input
								value={STUDENT.STUDENTTYPE.UNIVERSITY}
								onChange={handleStudentTypeChange}
								checked={
									studentDetailsFormik.values.userType ===
                  STUDENT.STUDENTTYPE.UNIVERSITY
								}
								className="form-check-input"
								type="radio"
								name={STUDENT.FORMIK.USERTYPE}
								id={STUDENT.STUDENTTYPE.UNIVERSITY}
							/>
							<label className="form-check-label" htmlFor="university">
                University
							</label>
						</div>
						<div className="form-check">
							<input
								value={STUDENT.STUDENTTYPE.GENERAL}
								onChange={handleStudentTypeChange}
								checked={
									studentDetailsFormik.values.userType ===
                  STUDENT.STUDENTTYPE.GENERAL
								}
								className="form-check-input"
								type="radio"
								name={STUDENT.STUDENTTYPE.GENERAL}
								id={STUDENT.STUDENTTYPE.GENERAL}
							/>
							<label className="form-check-label" htmlFor="general">
                General
							</label>
						</div>
					</div>
					<div className={styles.fieldStyle}>
						<Form.Group className="from-group" controlId="formFirstName">
							<Form.Control
								type="text"
								name="firstName"
								placeholder="First Name"
								value={studentDetailsFormik.values.firstName}
								onChange={studentDetailsFormik.handleChange}
							/>
						</Form.Group>
						{studentDetailsFormik.errors.firstName &&
            studentDetailsFormik.touched.firstName ? (
								<span className={commonstyles.error}>
									{studentDetailsFormik.errors.firstName}
								</span>
							) : (
								<span></span>
							)}
					</div>
					<div className={styles.fieldStyle}>
						<Form.Group className="from-group" controlId="formLastName">
							<Form.Control
								type="text"
								name="lastName"
								placeholder="Last Name"
								value={studentDetailsFormik.values.lastName}
								onChange={studentDetailsFormik.handleChange}
							/>
						</Form.Group>
						{studentDetailsFormik.errors.lastName &&
            studentDetailsFormik.touched.lastName ? (
								<span className={commonstyles.error}>
									{studentDetailsFormik.errors.lastName}
								</span>
							) : (
								<span></span>
							)}
					</div>
					<div className={styles.fieldStyle}>
						<Form.Group controlId="formPhoneNumber">
							<PhoneInput
								international
								placeholder="Enter phone number"
								value={studentDetailsFormik.values.phoneNumber}
								onChange={(value) => {
									studentDetailsFormik.setFieldValue(
										INSTRUCTOR.FORMIK.PHONENUMBER,
										value
									);
								}}
							/>
						</Form.Group>
						{studentDetailsFormik.errors.phoneNumber &&
            studentDetailsFormik.touched.phoneNumber ? (
								<span className={commonstyles.error}>
									{studentDetailsFormik.errors.phoneNumber}
								</span>
							) : (
								<span></span>
							)}
					</div>{" "}
					<div className={styles.fieldStyle}>
						<Form.Group className="from-group" controlId="formEmail">
							<Form.Control
								name="email"
								type="text"
								placeholder="Email"
								value={studentDetailsFormik.values.email}
								onChange={studentDetailsFormik.handleChange}
							/>
						</Form.Group>
						{studentDetailsFormik.errors.email &&
            studentDetailsFormik.touched.email ? (
								<span className={commonstyles.error}>
									{studentDetailsFormik.errors.email}
								</span>
							) : (
								<span></span>
							)}
					</div>
					<div className={styles.fieldStyle}>
						<Form.Group className="from-group" controlId="formPassword">
							<Form.Control
								name="password"
								type="password"
								placeholder="Password"
								value={studentDetailsFormik.values.password}
								onChange={studentDetailsFormik.handleChange}
							/>
						</Form.Group>
						{studentDetailsFormik.errors.password &&
            studentDetailsFormik.touched.password ? (
								<span className={commonstyles.error}>
									{studentDetailsFormik.errors.password}
								</span>
							) : (
								<span></span>
							)}
					</div>
					<hr />
					{studentDetailsFormik.values.userType ===
          STUDENT.STUDENTTYPE.SCHOOL ? (
							<Fragment>
								<div className={styles.fieldStyle}>
									<Form.Group className="from-group" controlId="formUniversity">
										<Form.Control
											value={studentDetailsFormik.values.school}
											as="select"
											onChange={handleChange}
										>
											<option value={0} key={0}>
												{"Select School"}
											</option>
											{school?.map((item, id) => {
												return (
													<option value={item.id as number} key={id}>
														{item.name}
													</option>
												);
											})}
										</Form.Control>
									</Form.Group>
									{studentDetailsFormik.touched.school &&
                studentDetailsFormik.errors.school ? (
											<span className={commonstyles.error}>
												{studentDetailsFormik.errors.school}
											</span>
										) : null}
								</div>
							</Fragment>
						) : null}
					{studentDetailsFormik.values.userType ===
          STUDENT.STUDENTTYPE.UNIVERSITY ? (
							<Fragment>
								<div className={styles.fieldStyle}>
									<Form.Group className="from-group" controlId="formUniversity">
										<Form.Control
											value={studentDetailsFormik.values.university}
											as="select"
											onChange={handleUniversityChange}
										>
											<option value={0} key={0}>
												{"Select University"}
											</option>
											{universities?.map((university, id) => {
												return (
													<option value={university.id as number} key={id}>
														{university.name}
													</option>
												);
											})}
										</Form.Control>
									</Form.Group>
									{studentDetailsFormik.errors.university &&
                studentDetailsFormik.touched.university ? (
											<span className={commonstyles.error}>
												{studentDetailsFormik.errors.university}
											</span>
										) : (
											<span></span>
										)}
								</div>
								{studentDetailsFormik.values.university !== 0 && (
									<div className={styles.fieldStyle}>
										<Form.Group className="from-group" controlId="formDepartment">
											<Form.Control
												value={studentDetailsFormik.values.department}
												as="select"
												onChange={handleDepartmentChange}
											>
												<option value={0} key={0}>
                        Select Department
												</option>
												{department?.map((dep, id) => {
													return (
														<option value={dep.id as number} key={id}>
															{dep.name}
														</option>
													);
												})}
											</Form.Control>
										</Form.Group>
										{studentDetailsFormik.errors.department &&
                  studentDetailsFormik.touched.department ? (
												<span className={commonstyles.error}>
													{studentDetailsFormik.errors.department}
												</span>
											) : (
												<span></span>
											)}
									</div>
								)}
							</Fragment>
						) : null}
					{studentDetailsFormik.values.userType ===
            STUDENT.STUDENTTYPE.GENERAL && (
						<div className={styles.fieldStyle}>
							<Form.Group className="from-group" controlId="formUniversity">
								<Form.Control
									value={studentDetailsFormik.values.trainingField}
									as="select"
									onChange={handleTrainingChange}
								>
									<option value={0} key={0}>
                    Select Training Field
									</option>
									{trainingFields?.map((item, id) => {
										return (
											<option value={item.id as number} key={id}>
												{item.name}
											</option>
										);
									})}
								</Form.Control>
							</Form.Group>
							{studentDetailsFormik.touched.trainingField &&
              studentDetailsFormik.errors.trainingField ? (
									<span className={commonstyles.error}>
										{studentDetailsFormik.errors.trainingField}
									</span>
								) : null}
						</div>
					)}
					<div className={styles.fieldStyle}>
						<Form.Group className="from-group" controlId="formFirstName">
							<Form.Control
								type="number"
								name={STUDENT.FORMIK.DEVICERESTRICTION}
								placeholder="Device Restriction"
								value={studentDetailsFormik.values.deviceRestriction}
								onChange={studentDetailsFormik.handleChange}
							/>
						</Form.Group>
						{studentDetailsFormik.errors.deviceRestriction &&
            studentDetailsFormik.touched.deviceRestriction ? (
								<span className={commonstyles.error}>
									{studentDetailsFormik.errors.deviceRestriction}
								</span>
							) : (
								<span></span>
							)}
					</div>
					<Button className={`${styles.buttonss} mt-3`} type="submit">
						{!id ? INSTRUCTOR.ADD : INSTRUCTOR.EDIT}
					</Button>
				</Form>
			</div>
		</Layout>
	);
};

export default AddOrEdit;
