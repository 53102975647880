import { axios } from "../../../../../lib/axios";
import { API_ROUTES } from "../../../../../utils/api_routes";
import { Pagination } from "../../../../common/interface";
// import { RemoveInstructorPayload, RemoveInstructorRespose } from "../../../../instructor/api/interface";
import { SegmentResponse, SegmentationPayload, SpecificSegmentResponse } from "./interface";

export const segmentList = (query: Pagination): Promise<SegmentResponse> => {
	return axios.get(`${API_ROUTES.COUPONS.SEGMENT}`, { params: query });
};
export const getSpecificSegment = (id:number): Promise<SpecificSegmentResponse> => {
	return axios.get(`${API_ROUTES.COUPONS.SEGMENT}/${id}`);
};
export const editSegmentation=(segmentation_id:number, payload:SegmentationPayload)=>{
	return axios.put(`${API_ROUTES.COUPONS.SEGMENT}/${segmentation_id}`, payload)
}
export const addSegmentation=(payload:SegmentationPayload)=>{
	return axios.post(`${API_ROUTES.COUPONS.SEGMENT}`, payload)
}
export const removeSegment = (id:number)=>{
	return axios.delete(`${API_ROUTES.COUPONS.SEGMENT}/${id}`);
};
