import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface Segmentation {
    id:Number,
    editOrAdd:String
}

const initialState: Segmentation = 
	{
		id:0,
		editOrAdd:"Add"
	}


export const segmentationSlice = createSlice({
	name: "segmentation",
	initialState,
	reducers: {
		segmentationModal: (state, action: PayloadAction<Segmentation>) => {
			state.editOrAdd=action.payload.editOrAdd
			state.id=action.payload.id

		},
	},
});

export const {segmentationModal } = segmentationSlice.actions;
export const segmentationSelector = (state: RootState) => state.segmentationReducer;

export default segmentationSlice.reducer;