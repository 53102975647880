export const INVOICE = {
	FORMIK: {
		NAME: "name",
		MOBILE: "mobile",
		EMAIL: "email",
		AMOUNT:"amount",
		EXPIRY:"expiry"

	
	},


}