export const COURSES = {
	FORMIK: {
		COURSE:"course",
		INSTRUCTOR:"instructor",
		UNIVERSITY: "university",
		DEPARTMENT: "departments",
		PRICE:"price",
		LINK:"link",
		SHARE:"share",
		SHARE_UNIT:"shareUnit",
		SHOW_INSTRUCTOR_SHARE:"showInstructorShare",
		DESCRIPTION:"description",
		COURSE_TYPE:"course_type",
		IMAGE:"image",
		DISCOUNT:"discount",
		ONLINE_PRICE:"onlinePrice",
		INPERSON_PRICE:"inPersonPrice"
		
	},
	ADDONS:{
		FORMIK:{
			NAME:"name",
			PRICE:"price",
			TYPE:"type"
		}
	},

	// REDUX: {
	// 	EDIT: "edit",
	// 	ADD: "add",
	// },

	// EDIT: "Edit",
	// ADD: "Add"
	PRICE:{
		STATUS:"status",
		OFFERPRICE:"offerPrice"
	}

}