import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface Coupon {
    id:Number,
	editOrAdd?: String
}

const initialState: Coupon = 
	{
		id:0,
		editOrAdd: "Add"
	}


export const couponSlice = createSlice({
	name: "coupon",
	initialState,
	reducers: {
		couponModal: (state, action: PayloadAction<Coupon>) => {
			
			state.id=action.payload.id
			state.editOrAdd = action.payload.editOrAdd

		},
	},
});

export const { couponModal } =couponSlice.actions;
export const couponSelector = (state: RootState) => state.couponReducer;

export default couponSlice.reducer;