import { PaginationInterface } from "../../../components/Pagination/interfaces";
import { axios } from "../../../lib/axios";
import { API_ROUTES } from "../../../utils/api_routes";
import { Pagination } from "../../common/interface";
import { ChaptersResponse, Course } from "../../course/api/interface";
import { RemoveInstructorPayload, RemoveInstructorRespose } from "../../instructor/api/interface";
import { AddCustomCoursePayload, AddStudentPayload, GetStudentResponse, PurchasedCourseResponse, SchoolListResponse, StudentListResponse, TrainingListResponse } from "./interface";

export const studentList = (query?: Pagination,university_id?:number,department_id?:number,course_id?:number): Promise<StudentListResponse> => {
	return axios.get(API_ROUTES.STUDENT.GETLIST, { params: {...query,...(university_id&&university_id!==0&&{university_id}),...(department_id&&department_id!==0&&{department_id}),...(course_id&&course_id!==0&&{course_id})} });
};
export const removeStudent=(query:RemoveInstructorPayload): Promise<RemoveInstructorRespose>=>{
	return axios.delete(`${API_ROUTES.STUDENT.REMOVE}${query.id}`)
}
export const addStudent=(query:AddStudentPayload): Promise<RemoveInstructorRespose>=>{
	return axios.post(API_ROUTES.STUDENT.ADD, query)

}
export const editStudent=(id:Number, query:AddStudentPayload): Promise<RemoveInstructorRespose>=>{
	return axios.post(`${API_ROUTES.STUDENT.EDIT}${id}`, query)
}
export const getStudent=(query:RemoveInstructorPayload): Promise<GetStudentResponse>=>{
	return axios.get(`${API_ROUTES.STUDENT.GETSTUDENT}${query.id}`)
}

export const courseList = (query: Pagination): Promise<RemoveInstructorRespose> => {
	return axios.get(API_ROUTES.COMMON.GETALLCOURSES, { params: query });
};
export const getAllSchools = (): Promise<SchoolListResponse> => {
	return axios.get(API_ROUTES.COMMON.GETALLSCHOOLS);
};
export const getAllTrainingFields = (): Promise<TrainingListResponse> => {
	return axios.get(API_ROUTES.COMMON.GETTRAININGFIELD);
};

export const getPurchasedCourses= (query:PaginationInterface, userId:number): Promise<PurchasedCourseResponse>=>{
	return axios.get(API_ROUTES.COURSES.PURCHASEDCOURSES, {params:{...query, user_id:userId}})
}

export const courseStatus=(id:number, status:1|2): Promise<RemoveInstructorRespose>=>{
	return axios.get(`${API_ROUTES.COURSES.CHANGESTATUS}/${id}/${status}`);
}
export const profileStatus=(id:number, status:1|2): Promise<RemoveInstructorRespose>=>{
	return axios.get(`${API_ROUTES.COURSES.CHANGEPROFILESTATUS}/${id}/${status}`);
}
export const courseListWithoutPagination = (): Promise<Course> => {
	return axios.get(API_ROUTES.COURSES.GETCOURSELIST);
};
export const getChapter=(id:number): Promise<ChaptersResponse>=>{
	return axios.get(API_ROUTES.COURSES.GETCHAPTERS, {params:{ course_id:id}});
}
export const addCourse=(query:AddCustomCoursePayload):Promise<RemoveInstructorPayload>=>{
	return axios.post(API_ROUTES.COURSES.BUYCOURSE, query)
}
