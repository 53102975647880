import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface User {
    avatar: string;
    name: string;
    
}

const initialState: User = 
	{
		avatar: "",
		name: "",
		
	}


export const authSlice = createSlice({
	name: "users",
	initialState,
	reducers: {
		authenticateUser: (state, action: PayloadAction<User>) => {
			state.name= action.payload.name;
			state.avatar=action.payload.avatar
		},
	},
});

export const { authenticateUser } = authSlice.actions;
export const authSelector = (state: RootState) => state.authReducer;

export default authSlice.reducer;