import React from "react";
import { useRoutes } from "react-router-dom";

import { publicRoutes } from "./public";
import { protectedRoutes } from "./protected";
import useAuth from "../lib/hooks/useAuth";
import { useAppSelector } from "../redux/hooks";

const AppRoutes = () => {
	const selectedTheme = useAppSelector((state) => state.themeReducer);

	const { ProtectedRoute } = useAuth();

	const protectedRoutesList = protectedRoutes.map((item) => ({
		...item,
		element: <ProtectedRoute component={item.element} />,
	}));

	const routes = [...protectedRoutesList, ...publicRoutes];

	const elements = useRoutes(routes);
	console.log(elements, " test here")

	return <div className={selectedTheme.theme}>{elements}</div>;
};

export default AppRoutes;
