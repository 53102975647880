import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKey } from "@fortawesome/free-solid-svg-icons";
import { Spinner } from "react-bootstrap";

import Layout from "../../../../components/Layout";
import commonStyles from "../../../common/styles/common.module.css";
import { getProfile } from "../../../../components/api";
import {
	GetProfileResponse,
	Profile as ProfileInterface,
} from "../../../../components/api/interface";
import ChangePassword from "../modals/ChangePassword";

export const Profile = () => {
	const [profile, setProfile] = useState<ProfileInterface>();
	const [showModal, setShowModal] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(true);

	const handleClose = () => {
		setShowModal(false);
	};

	useEffect(() => {
		getProfile().then((res: GetProfileResponse) => {
			setProfile(res.user);
			setLoading(false);
		});
	}, []);
	return (
		<Layout>
			<div className={commonStyles["outtre-pad"]}>
				<div className={commonStyles["heaing-filter"]}>
					<h3>Profile Management</h3>
				</div>
				{!loading ? (
					<div className={commonStyles["table-data"]}>
						<div className={`${commonStyles["property-head"]} pb-1`}>
							<ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
								<li className="nav-item" role="presentation"></li>
							</ul>
							<div className={commonStyles["filter-pro"]}>
								<button
									onClick={() => setShowModal(true)}
									className={commonStyles["add-lead"]}
								>
									<FontAwesomeIcon icon={faKey} /> Change Password
								</button>
							</div>
						</div>
						<div className="tab-content" id="pills-tabContent">
							<div
								className="tab-pane fade show active"
								id="pills-home"
								role="tabpanel"
								aria-labelledby="pills-home-tab"
							>
								<table>
									<tbody>
										<tr>
											<td className="text-center">Email</td>
											<td className="text-center">{profile?.email}</td>
										</tr>
										<tr>
											<td className="text-center">Firstname</td>
											<td className="text-center">{profile?.first_name}</td>
										</tr>
										<tr>
											<td className="text-center">Lastname</td>
											<td className="text-center">{profile?.last_name}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				) : (
					<div className="d-flex justify-content-center">
						<Spinner animation="border" role="status">
						</Spinner>
					</div>
				)}
			</div>
			<ChangePassword showModal={showModal} handleClose={handleClose} />
		</Layout>
	);
};
