import * as Yup from "yup";
import { YUP } from "../../../utils/validations";

const loginSchema = Yup.object().shape({
	email: Yup
		.string()
		.required(YUP.REQUIRED),
	password: Yup.string().required(YUP.REQUIRED)
});

export default loginSchema;