import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";

import "./index.css";
import "bootstrap/dist/css/bootstrap.css";
import "react-phone-number-input/style.css"


import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store } from "./redux/store";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

root.render(
	<Provider store={store}>	
		<App />
	</Provider>
);

reportWebVitals();
