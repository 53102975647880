import { configureStore } from "@reduxjs/toolkit";
import { authReducer, courseReducer, universityReducer, departmentReducer, chapterReducer, couponReducer, segmentationReducer, themeReducer, addonReducer, searchReducer } from "../reducers";

export const store = configureStore({
	reducer: {
		authReducer,
		courseReducer,
		universityReducer,
		departmentReducer,
		chapterReducer,
		couponReducer,
		segmentationReducer,
		themeReducer,
		addonReducer,
		searchReducer
	},
})

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

