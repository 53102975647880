import { PaginationInterface } from "../../../../../components/Pagination/interfaces";
import { axios } from "../../../../../lib/axios";
import { API_ROUTES } from "../../../../../utils/api_routes";
import { Pagination } from "../../../../common/interface";
import { RemoveInstructorRespose } from "../../../../instructor/api/interface";
import { InstructorStudentListResponse } from "../../../../student/api/interface";
import { AddCourseParam, ApproveCoursePayload, Chapter, ChaptersResponse, Course, ParticularChapterResponse } from "./interface";

export const courseList = (query:Pagination, id:Number): Promise<Course> => {
	return axios.get(API_ROUTES.COURSES.GETCOURSELIST, {params:{...query, instructor_id:id}});
};

export const addCourse=(params:AddCourseParam):Promise<RemoveInstructorRespose>=>{
	return axios.post(API_ROUTES.COURSES.GETCOURSELIST, params);
}

export const editCourse=(params:AddCourseParam, id:number):Promise<RemoveInstructorRespose>=>{
	return axios.post(`${API_ROUTES.COURSES.GETCOURSELIST}/${id}`, params);
}

export const course = (id:string, query:PaginationInterface): Promise<Chapter> => {
	console.log(query)
	return axios.get(`${API_ROUTES.COURSES.GETCOURSE}${id}`);
};

export const getChapter=(id:string, query:Pagination): Promise<ChaptersResponse>=>{
	return axios.get(API_ROUTES.COURSES.GETCHAPTERS, {params:{...query, course_id:id}});
}
export const getParticularChapter=(id:number):Promise<ParticularChapterResponse>=>{
	return axios.get(`${API_ROUTES.COURSES.GETCHAPTERS}/${id}`);
}

export const approveCourse=(query:ApproveCoursePayload): Promise<RemoveInstructorRespose>=>{
	return axios.post(API_ROUTES.COURSES.APPROVECOURSE, query)

}
export const removeCourse=(id:number):Promise<RemoveInstructorRespose>=>{
	return axios.delete(`${API_ROUTES.COURSES.REMOVECOURSE}${id}`);
}
export const editChapter=(id:number, online_price:number, course_id:number):Promise<RemoveInstructorRespose>=>{
	return axios.put(`${API_ROUTES.COURSES.GETCHAPTERS}/${id}`, {price:online_price, course_id})
}

export const getStudentByCourse=(course_id:number, query:Pagination):Promise<InstructorStudentListResponse>=>{
	return axios.get(`${API_ROUTES.COURSES.GETSTUDENTS}`, {params:{...query, course_id}})
}