import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { AddStudentProp } from "./interface";
import detailstyle from "../styles/detail.module.css";
import { addCourse, courseListWithoutPagination, getChapter } from "../api";
import { ChapterInfo, CourseDeatails } from "../../course/api/interface";
import { AddCourseSchema } from "../validations";
import { useFormik } from "formik";
import commonstyles from "../../instructor/styles/common.module.css";
import { AddCustomCoursePayload } from "../api/interface";
import { MESSAGES } from "../../../utils/messages";
import { STUDENT } from "../../../utils/student";

const AddCourse = ({ showModal, handleClose }: AddStudentProp) => {
	const { id } = useParams();
	const [courses, setCourses] = useState<CourseDeatails[]>([]);
	const [chapters, setChapters] = useState<ChapterInfo[]>([]);

	const CourseDetailsFormik = useFormik({
		initialValues: {
			amount: 0,
			paymentLink: "",
			course: 0,
			chapter: 0,
		},
		enableReinitialize: true,
		onSubmit: (values) => {
			const payload: AddCustomCoursePayload = {
				amount: values.amount,
				student_id: Number(id),
			};
			if (Number(values.chapter) === 0) {
				payload.course_id = Number(values.course);
			} else {
				payload.chapter_id = Number(values.chapter);
			}
			if (values.paymentLink !== "") payload.payment_link = values.paymentLink;
			toast.promise(addCourse(payload), {
				pending: {
					render() {
						return MESSAGES.STUDENT.ADDCOURSE;
					},
				},
				success: {
					render() {
						CourseDetailsFormik.resetForm();
						handleClose();
						return MESSAGES.STUDENT.ADDEDCOURSE;
					},
				},
				error: {
					render() {
						return MESSAGES.STUDENT.NOTADDEDCOURSE;
					},
				},
			});
		},

		validationSchema: AddCourseSchema,
	});

	const handleCourseChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		getChapters(Number(e.target.value));
	};
	const getChapters = (courseId: number) => {
		if (courseId === 0) {
			setChapters([]);
		} else {
			getChapter(courseId).then((res) => {
				setChapters(res.data);
			});
		}
	};

	useEffect(() => {
		courseListWithoutPagination().then((res) => {
			setCourses(res.data);
		});
	}, []);
	const closeModal = () => {
		handleClose();
		CourseDetailsFormik.resetForm();
	};

	return (
		<Modal
			show={showModal}
			onHide={closeModal}
			id="exampleModal"
			aria-labelledby="exampleModalLabel"
			aria-hidden="true"
		>
			<Modal.Header closeButton>
				<Modal.Title>Add Course</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className={detailstyle["add-course"]}>
					<Form.Group>
						<Form.Label>Course</Form.Label>
						<Form.Select
							value={CourseDetailsFormik.values.course}
							name={STUDENT.FORMIK.COURSE}
							onChange={(e) => {
								CourseDetailsFormik.handleChange(e);
								handleCourseChange(e);
							}}
						>
							<option value={0} key={0}>
                Choose Course
							</option>
							{courses.map((course) => (
								<option value={course.id} key={course.id}>
									{course.name}
								</option>
							))}
						</Form.Select>
						{CourseDetailsFormik.errors.course &&
            CourseDetailsFormik.touched.course ? (
								<p className={commonstyles.error}>
									{CourseDetailsFormik.errors.course}
								</p>
							) : (
								<span></span>
							)}
						<Form.Label>Chapter</Form.Label>

						<Form.Select
							name={STUDENT.FORMIK.CHAPTER}
							onChange={CourseDetailsFormik.handleChange}
						>
							<option value={0} key={0}>
                Choose Chapter
							</option>
							{chapters.map((chapter) => (
								<option value={chapter.id} key={chapter.id}>
									{chapter.name}
								</option>
							))}
						</Form.Select>
					</Form.Group>
					<Form.Label>Amount</Form.Label>

					<div className="form-group">
						<Form.Control
							onKeyDown={(e) => {
								if (e.key === "e" || e.key === "E") {
									e.preventDefault();
								}
							}}
							name={STUDENT.FORMIK.AMOUNT}
							type="number"
							placeholder="Amount"
							onChange={CourseDetailsFormik.handleChange}
						/>
						{CourseDetailsFormik.errors.amount &&
            CourseDetailsFormik.touched.amount ? (
								<span className={commonstyles.error}>
									{CourseDetailsFormik.errors.amount}
								</span>
							) : (
								<span></span>
							)}
					</div>
					<div className="form-group">
						<Form.Label>Payment Link</Form.Label>

						<Form.Control
							name={STUDENT.FORMIK.PAYMENTLINK}
							type="text"
							placeholder="Payment Link"
							onChange={CourseDetailsFormik.handleChange}
						/>
						{CourseDetailsFormik.errors.paymentLink &&
            CourseDetailsFormik.touched.paymentLink ? (
								<span className={commonstyles.error}>
									{CourseDetailsFormik.errors.paymentLink}
								</span>
							) : (
								<span></span>
							)}
					</div>
					<div className="form-group">
						<Button onClick={CourseDetailsFormik.submitForm}>Add</Button>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default AddCourse;
