import React, { useEffect } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { Modal, Form, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";

import { useAppSelector } from "../../../redux/hooks";
import { INSTRUCTOR } from "../../../utils/instructor";
import {  addDepartment, addStory, editDepartment, getDepartment } from "../api";
import  { StorySchema } from "../validations";
import styles from "../../instructor/styles/instructor.module.css";
import commonstyles from "../../instructor/styles/common.module.css";
import { UNIVERSITY } from "../../../utils/university";
// import { MESSAGES } from "../../../utils/messages";
import { AddUniversityProp, Department } from "./interface";
import { MESSAGES } from "../../../utils/messages";
import { uploadImage } from "../../common/utils";

const AddOrEditStory = ({ showModal, handleClose }: AddUniversityProp) => {
	 const { id } = useParams();


	

	const departmentDetailsFormik = useFormik({
		initialValues: {
            Title:"",
            image:null
		},
		enableReinitialize: true,
        validationSchema: StorySchema,
		onSubmit: (values) => {
			uploadImage(values.image).then((res)=>{

toast.promise(
					addStory(Number(id) ,res.data[0],values.Title),
					{
						pending: {
							render() {
								return "Adding Story"
							},
						},
						success: {
							render() {
								departmentDetailsFormik.resetForm();
								handleClose();
	
								return "Successfully Added Story"
							},
						},
						error: {
							render() {
								return "Failed to add story"
							},
						},
					}
				);

            })
			
		},

	});
	const handleAddDepartment = (e: React.SyntheticEvent<HTMLFormElement>) => {
		e.preventDefault();

		departmentDetailsFormik.submitForm();
	};

	return (
		<Modal
			show={showModal}
			onHide={() => {
				handleClose();
			}}
		>
			<div>
				<Modal.Header closeButton>
					<Modal.Title>
						Story
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form onSubmit={handleAddDepartment}>
                    <Form.Group className="from-group" controlId="formFirstName">
								<Form.Label>Title</Form.Label>
								<Form.Control
									type="text"
									placeholder="Title"
									value={departmentDetailsFormik.values.Title}
									name="Title"
									onChange={departmentDetailsFormik.handleChange}
								/>
							</Form.Group>
							{departmentDetailsFormik.errors.Title &&
              departmentDetailsFormik.touched.Title ? (
									<span className={commonstyles.error}>
										{departmentDetailsFormik.errors.Title}
									</span>
								) : null}
						
                    <Form.Group className="from-group" controlId="formFirstName">
								<Form.Label>Story</Form.Label>
								<Form.Control
									type="file"
									placeholder="Logo"
									accept="image/*"
									name="image"
									onChange={(event:any) => {
										departmentDetailsFormik.setFieldValue("image", event.currentTarget.files[0]);
									  }}
								/>
                                {departmentDetailsFormik.errors.image &&
              departmentDetailsFormik.touched.image ? (
									<span className={commonstyles.error}>
										{departmentDetailsFormik.errors.image}
									</span>
								) : null}
							</Form.Group>
							
						<Button className={`${styles.buttonss} mt-3`} type="submit">
							Add
						</Button>
					</Form>
				</Modal.Body>
			</div>
		</Modal>
	);
};

export default AddOrEditStory;
