export const INSTRUCTOR = {
	FORMIK: {
		FIRSTNAME: "firstName",
		LASTNAME: "lastName",
		PHONENUMBER: "phoneNumber",
		EMAIL: "email",
		UNIVERSITY: "university",
		DEPARTMENT: "department",
		PHONE_NUMBER_ERROR: "Enter valid Phone Number",
		PASSWORD: "password"
	},

	REDUX: {
		EDIT: "edit",
		ADD: "add",
	},

	EDIT: "Edit",
	ADD: "Add"

}