import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface University {
    id:Number,
    editOrAdd:String
}

const initialState: University = 
	{
		id:0,
		editOrAdd:"Add"
	}


export const universitySlice = createSlice({
	name: "university",
	initialState,
	reducers: {
		universityModal: (state, action: PayloadAction<University>) => {
			state.editOrAdd=action.payload.editOrAdd
			state.id=action.payload.id

		},
	},
});

export const { universityModal } = universitySlice.actions;
export const universitySelector = (state: RootState) => state.universityReducer;

export default universitySlice.reducer;