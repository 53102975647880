import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faCopy, faSearch } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { Button, Form, InputGroup, Spinner } from "react-bootstrap";
// import { toast } from "react-toastify";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { invoiceList } from "../api";
import Layout from "../../../components/Layout";
import commonStyles from "../../common/styles/common.module.css";
// import { Instructor } from "../api/interface";
import Pagination from "../../../components/Pagination";
import { PaginationInterface } from "../../../components/Pagination/interfaces";
// import { MESSAGES } from "../../../utils/messages";
import AddOrEdit from "../modals/AddOrEdit";
import { toast } from "react-toastify";
import { INVOICE_DATA } from "../api/interface";
import { useAppSelector } from "../../../redux/hooks";

const InvoiceList = () => {
  const [pagination, setPagination] = useState<PaginationInterface>({
    page: 1,
    limit: 10,
    count: 0,
    totalPages: 0,
  });
  const [invoices, setInvoices] = useState<INVOICE_DATA[]>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [search, setSearch] = useState<string>("");
  const [searchValue, setSearchValue] = useState<string>("");

  const handleSearch = () => {
    setSearchValue(search);
  };
  const searchText = useAppSelector((state) => state.searchReducer.text);

  useEffect(() => {
    setSearchValue(searchText as string)
  
    
  }, [searchText])
  // const [clipboard,setClipboard]=useState<any>({
  //     copied:false,
  //     value:""

  // })

  const refresh = () => {
    setLoading(true);
    const query = {
      per_page: pagination.limit,
      page: pagination.page,
      name:searchValue
    };

    invoiceList(query).then((res) => {
      const { total, last_page } = res.data;
      setPagination({
        ...pagination,
        count: total,
        totalPages: last_page,
      });

      setInvoices(res.data.data);
      setLoading(false);
    });
  };
  const handleClose = () => {
    setShowModal(false);

    if (pagination.page === 1) {
      refresh();
    } else {
      setPagination({ page: 1, limit: 10, count: 0, totalPages: 0 });
    }
  };

  // const handleRemoveInstructor = (id: Number) => {
  // 	toast.promise(removeInstructor({ id }), {
  // 		pending: {
  // 			render() {
  // 				return MESSAGES.INSTRUCTOR.REMOVING;
  // 			},
  // 		},
  // 		success: {
  // 			render({}) {
  // 				refresh();
  // 				return MESSAGES.INSTRUCTOR.REMOVED;
  // 			},
  // 		},
  // 		error: {
  // 			render() {
  // 				return MESSAGES.INSTRUCTOR.NOTREMOVED;
  // 			},
  // 		},
  // 	});
  // };

  const addNewInvoice = () => {
    // dispatch(instructorModal({ id: 0, editOrAdd: INSTRUCTOR.REDUX.ADD }));
    // navigate(APP_ROUTES.INSTRUCTORS_ADD);
    setShowModal(true);
  };

  // const editInstructor = (id: Number) => {
  // 	navigate(`${APP_ROUTES.INSTRUCTORS_LIST}/${id}`);
  // };
  // const viewCourses=(id:Number)=>{
  // 	navigate(`${ROUTE_CONSTANTS.INSTRUCTORS_COURSES}/${id}`)
  // }

  useEffect(() => {
    refresh();
  }, [pagination.page, searchValue]);

  return (
    <Layout>
      <div className={commonStyles["outtre-pad"]}>
        <div className={commonStyles["heaing-filter"]}>
          <h3>Invoice Management</h3>
          {/* <div className={commonStyles["filter-left"]}>
						<span>Filter: <select>
							<option>Location</option>
							<option>This Week</option>
						</select></span>
						<span>Filter: <select>
							<option>Price</option>
							<option>This Week</option>
						</select></span>
						<span>Filter: <select>
							<option>Department</option>
							<option>This Week</option>
						</select></span>
						<span>Filter: <select>
							<option>This Week</option>
							<option>This Week</option>
						</select></span>
					</div> */}
        </div>
        {!loading ? (
          <div className={`light-theme-table ${commonStyles["table-data"]}`}>
            <div className={`${commonStyles["property-head"]} pb-1`}>
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  {/* <button
									className={`nav-link ${commonStyles.activeTab}`}
									id="pills-home-tab"
									data-bs-toggle="pill"
									data-bs-target="#pills-home"
									type="button"
									role="tab"
									aria-controls="pills-home"
									aria-selected="true"
								>
                  All
								</button> */}
                </li>
              </ul>
              <div className={commonStyles["filter-pro"]}>
                <button
                  onClick={addNewInvoice}
                  className={commonStyles["add-lead"]}
                >
                  <FontAwesomeIcon icon={faPlus} /> Add New Invoice
                </button>
              </div>
            </div>
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <table>
                  <thead>
                    <tr>
                      <th>link</th>
                      <td>Name</td>
                      <td>Mobile</td>
                      <td>Email</td>
                      <td>Expiry Date</td>
                      <th>Created At</th>
                      {/* <th className="text-center">Total Courses</th>
											<th className="text-center">Total Students</th>
											<th>Registered At</th>
											<th>Status</th>
											<th>Action</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {invoices?.map((item) => (
                      <tr key={item.id}>
                        <td>
                          {item.link}

                          <CopyToClipboard
                            text={item.link}
                            onCopy={() => {
                              toast.success("payment link successfully Copied");
                            }}
                          >
                            <span>
                              {" "}
                              <FontAwesomeIcon icon={faCopy} />
                            </span>
                          </CopyToClipboard>
                        </td>
                        <td>{item.name}</td>
                        <td>
                          {item.country_code}-{item.mobile}
                        </td>
                        <td className="text-center">{item.email}</td>
                        <td>{moment(item.expiry).format("MMM DD, YYYY")}</td>
                        <td>
                          {moment(item.created_at).format("MMM DD, YYYY")}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" role="status"></Spinner>
          </div>
        )}
      </div>

      <Pagination
        paginationState={pagination}
        setPaginationState={setPagination}
      />
      <AddOrEdit showModal={showModal} handleClose={handleClose} />
    </Layout>
  );
};

export default InvoiceList;
