import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface Search {
    
	text?: String
}

const initialState: Search = 
	{
		
		text: ""
	}


export const searchSlice = createSlice({
	name: "search",
	initialState,
	reducers: {
		setSearch: (state, action: PayloadAction<Search>) => {
			
			state.text=action.payload.text
			

		},
	},
});

export const { setSearch } = searchSlice.actions;
export const searchSelector = (state: RootState) => state.searchReducer;

export default searchSlice.reducer;