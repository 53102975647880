import { MESSAGES } from "./messages";

const STUDENT_TYPE = {
	STUDENT: 1,
	UNIVERSITY: 2,
	GENERAL: 3,
};
const MONTHS = [
	{ id: 1, name: "January" },
	{ id: 2, name: "February" },
	{ id: 3, name: "March" },
	{ id: 4, name: "April" },
	{ id: 5, name: "May" },
	{ id: 6, name: "June" },
	{ id: 7, name: "July" },
	{ id: 8, name: "August" },
	{ id: 9, name: "September" },
	{ id: 10, name: "October" },
	{ id: 11, name: "November" },
	{ id: 12, name: "December" }
];

export {
	STUDENT_TYPE,
	MESSAGES,
	MONTHS
};