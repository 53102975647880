import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
// import { AddInstructorProp } from "./interface";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { Button, Form, Modal } from "react-bootstrap";
import users from "../../../assets/images/users.png"
import cpy from "../../../assets/images/cpy.png"
import shr from "../../../assets/images/shr.png"
// import { addCourse, course, editCourse } from "../api";
import styles from "../../instructor/styles/instructor.module.css";
import commonstyles from "../../instructor/styles/common.module.css";
//import commonstyles from "../../instructor/styles/common.module.css";
// import { CourseDetailsSchema } from "../validations";
//import { INSTRUCTOR } from "../../../utils/instructor";
//import { useAppSelector } from "../../../redux/hooks";
// import {
// 	DepartmentInterface,
// 	Instructor,
// 	UniversityInterface,
// } from "../../instructor/api/interface";
// import {
// 	getAllInstructorUniversity,
// 	getAllUniversities,
// } from "../../instructor/api";
// // import { AddCourseParam } from "../api/interface";
// import { PaginationInterface } from "../../../components/Pagination/interfaces";
// import { COURSES } from "../../../utils/courses";
// import { MESSAGES } from "../../../utils/messages";
// import { YUP } from "../../../utils/validations";
import { AddInstructorProp } from "../../course/modals/interface";
import { addCoupon, coupon, editCoupon } from "../api";
import { CouponDetails } from "../api/interface";
import { useAppSelector } from "../../../redux/hooks";
import { INSTRUCTOR } from "../../../utils/instructor";
import { COUPONS } from "../../../utils/coupons";
import { MESSAGES } from "../../../utils/messages";
import { CouponDetailsSchema } from "../validations";
import {  UniversityInterface } from "../../instructor/api/interface";
import { getAllUniversities } from "../../instructor/api";
import { COURSES } from "../../../utils/courses";
import { courseListWithoutPagination } from "../../student/api";
import { CourseDeatails } from "../../course/api/interface";
import { CouponFormik, SelectedUni } from "./interface";
import { uploadImage } from "../../common/utils";

const AddorEdit = ({ showModal, handleClose }: AddInstructorProp) => {
	const selectedCoupon = useAppSelector((state) => state.couponReducer);
	const [universities, setUniversities] = useState<UniversityInterface[]>([]);
	// const [department, setDepartment] = useState<DepartmentInterface[]>();
	const [course, setCourse] = useState<CourseDeatails[]>([]);
	const [selectedCourses, setSelectedCourses] = useState<number[]>([]);
	// const [selectedUniversity, setSelectedUniversity] = useState<any>([]);
	const [selectedCourse, setSelectedCourse] = useState<number >(0);

	const [loading, setLoading]=useState<boolean>(false)

	const handleCourseCheckboxClick = (courseId: number) => {
		couponsDetailsFormik.setFieldValue(COUPONS.FORMIK.SELECTED_COURSE, courseId);

	  setSelectedCourse(courseId);
	};

	const [selectedDepartments, setSelectedDepartments] = useState<number[]>([]);
	// const [selectedUniversities, setSelectedUniversities] = useState<number[]>([]);


	useEffect(() => {
		getAllUniversities().then((res) => {
			setUniversities(res?.data);
		});
		courseListWithoutPagination().then((res)=>{
			const {data}=res
			setCourse(data);
		})
	}, [showModal]);
	

	// const addDepartments = (id: Number) => {
	// 	if (id === 0) {
	// 		setDepartment([]);
	// 		return;
	// 	}
	// 	const selectedUniversity = universities.filter(
	// 		(uni: UniversityInterface) => {
	// 			return uni.id === id;
	// 		}
	// 	);
	// 	setDepartment(selectedUniversity[0]?.universityDepartments);
	// };
	// const handleUniversityChange = (e: any) => {
	// 	couponsDetailsFormik.handleChange(e);
	// 	addDepartments(Number(e.target.value));
		
	// };

	// const handleCheckboxClick = (e: React.ChangeEvent<HTMLInputElement>) => {
	// 	couponsDetailsFormik.setFieldError(COURSES.FORMIK.DEPARTMENT, "");
	// 	if (e.target.checked === true) {
	// 		setSelectedDepartments((prevState)=>[...prevState, Number(e.target.value)]);
	// 	} else {
	// 		const selectedDepartmentsCopy = selectedDepartments;
	// 		const selectedDepartmentsList = selectedDepartmentsCopy.filter((num) => {
	// 			return num !== Number(e.target.value);
	// 		});
	// 		setSelectedDepartments(selectedDepartmentsList);
	// 	}
		
	// };

	const  handleUniversityCheckboxClick = (e:React.ChangeEvent<HTMLInputElement>, id:number) => {
		const array:SelectedUni[]=couponsDetailsFormik.values.selectedUniversities
		console.log(array, id)
		if(e.target.checked)
		{
			couponsDetailsFormik.setFieldValue(COUPONS.FORMIK.SELECTED_UNIVERSITIES, [...array, { university_id: id, discount: "", discount_type: "2" }])

		}
		else{
				
				  let arr=array.filter((u)=>{
				return u.university_id!==id
				  })
				  couponsDetailsFormik.setFieldValue(COUPONS.FORMIK.SELECTED_UNIVERSITIES, arr)

		}

			  };
	
	// const handleUniversityCheckboxClick = (e: React.ChangeEvent<HTMLInputElement>) => {
	// 	couponsDetailsFormik.setFieldError("university", "");
		
	// 	if (e.target.checked === true) {
	// 		setSelectedUniversities((prevState)=>[...prevState, Number(e.target.value)]);
	// 		setSelectedUniversity((prevState:any)=>[...prevState, {university_id:Number(e.target.value),discount_type:1}])
	// 	} else {
	// 		const selectedUniversityCopy = selectedUniversities;
	// 		const selectedUniversityList = selectedUniversityCopy.filter((num) => {
	// 			return num !== Number(e.target.value);
	// 		});
	// 		setSelectedUniversities(selectedUniversityList);
	// 		const selectedUniversityCopyc = selectedUniversity;
	// 		const selectedUniversityListc = selectedUniversityCopyc.filter((num:any) => {
	// 			return num.university_id !== Number(e.target.value);
	// 		});
	// 		setSelectedUniversity(selectedUniversityListc);
	// 	}
		
		
	// };
	// const handlePriceChange=(id:number,e:any)=>{
	// 	let updatedArray=selectedUniversity;
	// 	updatedArray=selectedUniversity.map((obj:any)=>{
	// 		if(obj.university_id===id)
	// 			{
	// 				return {
	// 					...obj,discount:e.target.value
	// 				}
	// 			}
	// 			else{
	// 				return {...obj}
	// 			}
	// 	})
	// 	setSelectedUniversity(updatedArray)

	// }
	// const handleUnitChange=(id:number,e:any)=>{
	// 	let updatedArray=selectedUniversity;
	// 	updatedArray=selectedUniversity.map((obj:any)=>{
	// 		if(obj.university_id===id)
	// 			{
	// 				return {
	// 					...obj,discount_type:e.target.value
	// 				}
	// 			}
	// 			else{
	// 				return {...obj}
	// 			}
	// 	})
	// 	setSelectedUniversity(updatedArray)


	// }
	type SelectedUniKeys = keyof SelectedUni;
	const searchUniversity=(id:number, param:SelectedUniKeys)=>{
		const index=couponsDetailsFormik.values.selectedUniversities.findIndex(
			(u) => u.university_id === id
		  )
		  
		return couponsDetailsFormik.values.selectedUniversities[index][param]

		
	}


	const handleCoursesCheckboxClick = (e: React.ChangeEvent<HTMLInputElement>) => {
		couponsDetailsFormik.setFieldError(COUPONS.FORMIK.COURSES, "");
		if (e.target.checked === true) {
			setSelectedCourses((prevState)=>[...prevState, Number(e.target.value)]);
		} else {
			const selectedCoursesCopy = selectedCourses;
			const selectedCoursesList = selectedCoursesCopy.filter((num) => {
				return num !== Number(e.target.value);
			});
			setSelectedCourses(selectedCoursesList);
		}
		
	};

	useEffect(() => {
		couponsDetailsFormik.setFieldValue(COURSES.FORMIK.DEPARTMENT, selectedDepartments)
	}, [selectedDepartments])
	useEffect(() => {
		couponsDetailsFormik.setFieldValue(COUPONS.FORMIK.COURSES, selectedCourses)
	}, [selectedCourses])


	useEffect(() => {
		if (selectedCoupon.editOrAdd === INSTRUCTOR.REDUX.EDIT) {
			coupon(String(selectedCoupon.id)).then((res) => {
				
				const {data:{backgroud_color, text, text_color, type, button_color, code, courses_count,  expiry_date, max_members,  departments, courses, universities}}=res;
				console.log(type.toLowerCase())
				if(type.toLowerCase()===COUPONS.TYPES.GROUP_COUPON)
				{
					couponsDetailsFormik.setFieldValue(COUPONS.FORMIK.COUPON_TYPE, 1)
				}
				else if(type.toLowerCase()===COUPONS.TYPES.COURSE_DISCOUNT)
				{
					couponsDetailsFormik.setFieldValue(COUPONS.FORMIK.COUPON_TYPE, 2)
				}
				else if(type.toLowerCase()===COUPONS.TYPES.SPECIAL_DISCOUNT)
				{
					couponsDetailsFormik.setFieldValue(COUPONS.FORMIK.COUPON_TYPE, 4)
				}
				else if(type.toLowerCase()===COUPONS.TYPES.SPECIAL_PACKAGE)
				{
					couponsDetailsFormik.setFieldValue(COUPONS.FORMIK.COUPON_TYPE, 3)
				}
				else
				{
					couponsDetailsFormik.setFieldValue(COUPONS.FORMIK.COUPON_TYPE, 5)
				}
				couponsDetailsFormik.setFieldValue(COUPONS.FORMIK.SELECTED_UNIVERSITIES, universities)
				// addDepartments(university_id)
				const depList:number[]=[];
				departments.map((dep)=>{
					depList.push(dep.department_id)
				})
				const courseList:number[]=[];
				courses.map((course)=>{
					courseList.push(course.course.id)
				})
				couponsDetailsFormik.setFieldValue(COUPONS.FORMIK.DEPARTMENTS, depList)
				setSelectedDepartments(depList)
				couponsDetailsFormik.setFieldValue(COUPONS.FORMIK.COURSES, courseList)
				setSelectedCourses(courseList)

				couponsDetailsFormik.setFieldValue(COUPONS.FORMIK.BACKGROUND_COLOR, backgroud_color)

				couponsDetailsFormik.setFieldValue(COUPONS.FORMIK.TEXT_COLOR, text_color)

				couponsDetailsFormik.setFieldValue(COUPONS.FORMIK.BUTTON_COLOR, button_color)

				couponsDetailsFormik.setFieldValue(COUPONS.FORMIK.CODE, code)
			
			
				couponsDetailsFormik.setFieldValue(COUPONS.FORMIK.DATE, expiry_date)

				
				couponsDetailsFormik.setFieldValue(COUPONS.FORMIK.LIMIT, courses_count?courses_count:0)

				couponsDetailsFormik.setFieldValue(COUPONS.FORMIK.TEXT, text?text:"")
			
				
				couponsDetailsFormik.setFieldValue(COUPONS.FORMIK.MEMBERS, max_members)
				
				
			});
		} else {
			couponsDetailsFormik.resetForm();
			
		}
	}, [selectedCoupon.editOrAdd, showModal]);
	

	const couponsDetailsFormik = useFormik<CouponFormik>({
		

		initialValues: {
			couponType:1,
			backgroundColor:"#000000",
			textColor:"#000000",
			buttonColor:"#000000",
			code:"",
			members:0,
			date:"",
			text:"",
			limit:0,
			selectedUniversities: [],
			courses:[],
			selectedCourse:0,
			bannerImage:"",
			gradientFrom:"",
			gradientTo:"",
			discount_amount:"",
			discount_type:"2"

		},
		 validationSchema: CouponDetailsSchema,
		enableReinitialize: true,
		onSubmit: (values) => {
			console.log(values)
			const payload:CouponDetails={
				type:values.couponType,
				code:values.code,
				
				backgroud_color:values.backgroundColor,
				text_color:values.textColor,
				button_color:values.buttonColor,
				expiry_date:values.date,
				
				
				// university_id:values.university,
				// departments:values.departments


			}
			if(Number(values.couponType)!==6)
			{
				payload.universities=values.selectedUniversities
			}
			if(Number(values.couponType)===1)
			{
				payload.max_members=values.members
			}
			if(Number(values.couponType)===5||Number(values.couponType)===2)
			{
				payload.text=values.text
				payload.courses_count=values.limit
				payload.courses=values.courses
			}
			if(Number(values.couponType)===4)
			{
				payload.text=values.text;
				payload.courses=[selectedCourse] 
			}
			if(Number(values.couponType)===4)
			{
				payload.text=values.text;
				payload.courses=[selectedCourse] 
			}
			if(Number(values.couponType)===3)
			{
				payload.image=values.bannerImage,
				payload.gradient_from=values.gradientFrom,
				payload.gradient_to=values.gradientTo
			}
			if(Number(values.couponType)===6)
			{
				payload.discount_amount=(values.discount_amount),
				payload.discount_type=values.discount_type
			}
			
			toast.promise( 
				selectedCoupon.editOrAdd === INSTRUCTOR.REDUX.ADD
					? addCoupon(payload)
					: editCoupon(payload, selectedCoupon.id as number),
				{
					pending: {
						render() {
							return selectedCoupon.editOrAdd === INSTRUCTOR.REDUX.ADD
								?  MESSAGES.COUPON.ADD
								: MESSAGES.COUPON.EDIT
										
						},
					},
					success: {
						render() {
							couponsDetailsFormik.resetForm();
							handleClose();
							return	selectedCoupon.editOrAdd === INSTRUCTOR.REDUX.ADD
								?  MESSAGES.COUPON.SUCCESS
								: MESSAGES.COUPON.EDITED
			
										
						},
					},
					error: {
						render() {
							return	selectedCoupon.editOrAdd === INSTRUCTOR.REDUX.ADD
								?  MESSAGES.COUPON.INVALID
								:  MESSAGES.COUPON.NOTEDITED
										
						},
					},
				}
			);
			
		
		},
	});

	// const selectedCourse = useAppSelector((state) => state.courseReducer);
	// const [instructors, setInstructors] = useState<Instructor[]>([]);
	// const [universities, setUniversities] = useState<UniversityInterface[]>([]);
	// const [department, setDepartment] = useState<DepartmentInterface[]>();
	// const [selectedDepartments, setSelectedDepartments] = useState<number[]>([]);
	// const [pagination, setPagination] = useState<PaginationInterface>({
	// 	page: 1,
	// 	limit: 10,
	// 	count: 0,
	// 	totalPages: 0,
	// });

	// const addInstructor = (id: number) => {
	// 	getAllInstructorUniversity(id).then((res) => {
	// 		setInstructors(res.data);
	// 		console.log(setPagination);
	// 	});
	// };
	// useEffect(() => {
	// 	getAllUniversities().then((res) => {
	// 		setUniversities(res?.data);
	// 	});
	// }, [showModal]);

	// useEffect(() => {
	// 	if (selectedCourse.editOrAdd === INSTRUCTOR.REDUX.EDIT) {
	// 		course(String(selectedCourse.id), pagination).then((res) => {
	// 			const { name, instructor, university, departments, full_price } = res.data;

	// 			courseDetailsFormik.setFieldValue(COURSES.FORMIK.COURSE, name);
	// 			courseDetailsFormik.setFieldValue(COURSES.FORMIK.PRICE, full_price)
	// 			addInstructor(university.id as number);
	// 			courseDetailsFormik.setFieldValue(
	// 				COURSES.FORMIK.INSTRUCTOR,
	// 				instructor.id
	// 			);
	// 			courseDetailsFormik.setFieldValue(
	// 				COURSES.FORMIK.UNIVERSITY,
	// 				university.id
	// 			);
	// 			addDepartments(Number(university.id));
	// 			const depList = departments.map((dep) => dep.department_id);
	// 			setSelectedDepartments(depList);
	// 		});
	// 	} else {
	// 		courseDetailsFormik.resetForm();
	// 		setDepartment([]);
	// 		setSelectedDepartments([]);
	// 	}
	// }, [selectedCourse.editOrAdd, showModal]);

	// const addDepartments = (id: Number) => {
	// 	if (id === 0) {
	// 		setDepartment([]);
	// 		return;
	// 	}
	// 	const selectedUniversity = universities.filter(
	// 		(uni: UniversityInterface) => {
	// 			return uni.id === id;
	// 		}
	// 	);
	// 	setDepartment(selectedUniversity[0]?.universityDepartments);
	// };
	// const handleUniversityChange = (e: any) => {
	// 	courseDetailsFormik.handleChange(e);
	// 	addDepartments(Number(e.target.value));
	// 	if (Number(e.target.value) === 0) {
	// 		setInstructors([]);
	// 		return;
	// 	}
	// 	addInstructor(Number(e.target.value));
	// };

	// const handleCheckboxClick = (e: React.ChangeEvent<HTMLInputElement>) => {
	// 	courseDetailsFormik.setFieldError(COURSES.FORMIK.DEPARTMENT, "");
	// 	if (e.target.checked === true) {
	// 		setSelectedDepartments([...selectedDepartments, Number(e.target.value)]);
	// 	} else {
	// 		const selectedDepartmentsCopy = selectedDepartments;
	// 		const selectedDepartmentsList = selectedDepartmentsCopy.filter((num) => {
	// 			return num !== Number(e.target.value);
	// 		});
	// 		setSelectedDepartments(selectedDepartmentsList);
	// 	}
	// };

	// const handleAddCourse = (e: React.FormEvent<HTMLFormElement>) => {
	// 	e.preventDefault();
	// 	courseDetailsFormik.submitForm();
	// };

	// const courseDetailsFormik = useFormik({
	// // 	initialValues: {
	// // 		course: "",
	// // 		instructor: 0,
	// // 		departments: [],
	// // 		university: 0,
	// // 	},
	// // 	enableReinitialize: true,
	// // 	onSubmit: (values) => {
	// // 		const payload: AddCourseParam = {
	// // 			instructor_id: Number(values.instructor),
	// // 			departments: selectedDepartments,
	// // 			university_id: values.university,
	// // 			name: values.course,
	// // 			isPublished: 1,
	// // 		};
	// // 		if (selectedDepartments.length === 0) {
	// // 			courseDetailsFormik.setFieldError(
	// // 				COURSES.FORMIK.DEPARTMENT,
	// // 				YUP.REQUIRED
	// // 			);
	// // 			return;
	// // 		}
	// // 		toast.promise(
	// // 			selectedCourse.editOrAdd === INSTRUCTOR.REDUX.ADD
	// // 				? addCourse(payload)
	// // 				: editCourse(payload, selectedCourse.id as number),
	// // 			{
	// // 				pending: {
	// // 					render() {
	// // 						return selectedCourse.editOrAdd === INSTRUCTOR.REDUX.ADD
	// // 							? MESSAGES.COURSE.ADD
	// // 							: MESSAGES.COURSE.EDIT;
	// // 					},
	// // 				},
	// // 				success: {
	// // 					render() {
	// // 						courseDetailsFormik.resetForm();
	// // 						handleClose();

	// // 						return selectedCourse.editOrAdd === INSTRUCTOR.REDUX.ADD
	// // 							? MESSAGES.COURSE.ADDED
	// // 							: MESSAGES.COURSE.EDITED;
	// // 					},
	// // 				},
	// // 				error: {
	// // 					render() {
	// // 						return selectedCourse.editOrAdd === INSTRUCTOR.REDUX.ADD
	// // 							? MESSAGES.COURSE.NOTADDED
	// // 							: MESSAGES.COURSE.NOTEDITED;
	// // 					},
	// // 				},
	// // 			}
	// // 		);
	// // 	},
	// // 	validationSchema: CourseDetailsSchema,
	//  });
	const handleImageUpload=(e:any)=>{
		setLoading(true);
		const file=e.target.files[0];
		uploadImage(file).then((res)=>{
			console.log(res);
			couponsDetailsFormik.setFieldValue("bannerImage", res.data[0])
			setLoading(false)
		})
	}
	return (
		<Modal
			show={showModal}
			onHide={() => {
				handleClose();
			}}
		>
			<div className={styles["add-lead-from"]}>
				<Modal.Header closeButton>
					<Modal.Title>
						{"Coupons"}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body onClick={()=>{console.log(couponsDetailsFormik.errors)}}>
					<Form onSubmit={()=>{}}>
						<Form.Label>Background Color</Form.Label>

						<span className={styles.colorspan}>
							<input disabled={loading} value={couponsDetailsFormik.values.backgroundColor} onChange={couponsDetailsFormik.handleChange} name="backgroundColor"  type="color"/>
							<input disabled={loading} value={couponsDetailsFormik.values.backgroundColor} readOnly  name={COUPONS.FORMIK.BACKGROUND_COLOR} className="colortext" type="text"/>
						</span>
						<div className={styles.fieldStyle}>
							<Form.Label>Text Color</Form.Label>

							<span className={styles.colorspan}>
								<input disabled={loading} value={couponsDetailsFormik.values.textColor} name={COUPONS.FORMIK.TEXT_COLOR} onChange={couponsDetailsFormik.handleChange}  type="color"/>
								<input  readOnly value={couponsDetailsFormik.values.textColor}  className="colortext" type="text"/>
							</span>
							<Form.Label>Button Color</Form.Label>

							<span className={styles.colorspan}>
								<input value={couponsDetailsFormik.values.buttonColor}  onChange={couponsDetailsFormik.handleChange} name="buttonColor"  type="color"/>
								<input readOnly value={couponsDetailsFormik.values.buttonColor} name={COUPONS.FORMIK.BUTTON_COLOR} className="colortext" type="text"/>
							</span>
							<Form.Group className="from-group" controlId="formFirstName">
								<Form.Label>Coupon Code</Form.Label>
								<Form.Control disabled={loading} onChange={couponsDetailsFormik.handleChange}
									value={couponsDetailsFormik.values.code}
									type="text"
									placeholder="code"
									name={COUPONS.FORMIK.CODE}
									
								/>
							</Form.Group>
							{couponsDetailsFormik.errors.code &&
              couponsDetailsFormik.touched.code ? (
									<span className={commonstyles.error}>
										{couponsDetailsFormik.errors.code }
									</span>
								) : null}
							<Form.Group className="from-group" controlId="formFirstName">
								<Form.Label>Validity</Form.Label>
								<Form.Control
									disabled={loading}
									onChange={couponsDetailsFormik.handleChange}
									value={couponsDetailsFormik.values.date}
									type="date"
									placeholder="date"
									name={COUPONS.FORMIK.DATE}
									min={new Date().toJSON().slice(0, 10)}
									
								/>
							</Form.Group>
							{couponsDetailsFormik.errors.date &&
              couponsDetailsFormik.touched.date ? (
									<span className={commonstyles.error}>
										{couponsDetailsFormik.errors.date }
									</span>
								) : null}
							{/* {courseDetailsFormik.errors.course &&
              courseDetailsFormik.touched.course ? (
									<span className={commonstyles.error}>
										{courseDetailsFormik.errors.course}
									</span>
								) : null} */}
						</div>	
						<div className={styles.fieldStyle}>

							<Form.Group className="from-group" controlId="formUniversity">
								<Form.Label>Type</Form.Label>
								<Form.Control
									disabled={loading}
									name={COUPONS.FORMIK.COUPON_TYPE}
									as="select"
									value={couponsDetailsFormik.values.couponType}
									onChange={couponsDetailsFormik.handleChange}
								>
									
								
									<option value={1}>
										{"Group"}
									</option>
									<option value={2}>
										{"Course Discount"}
									</option>
									<option value={4}>
										{"Special Discount"}
									</option>
									<option value={3}>
										{"Special Package"}
									</option>
									<option value={5}>
										{"voucher"}
									</option>
									<option value={6}>
										{"Simple Coupon"}
									</option>
										
								
								</Form.Control>
							</Form.Group>
						
						</div>	
						{/* <div className={styles.fieldStyle}>

							<Form.Group className="from-group" controlId="formUniversity">
								<Form.Label>University</Form.Label>
								<Form.Control
									name={COURSES.FORMIK.UNIVERSITY}
									value={courseDetailsFormik.values.university}
									as="select"
									onChange={(e) => handleUniversityChange(e)}
								>
									<option value={0} key={0}>
                    Select University
									</option>
									{universities?.map((university, id) => (
										<option value={university.id} key={id}>
											{university.name}
										</option>
									))}
								</Form.Control>
							</Form.Group>
							{courseDetailsFormik.errors.university &&
              courseDetailsFormik.touched.university ? (
									<span className={commonstyles.error}>
										{courseDetailsFormik.errors.university}
									</span>
								) : null}
						</div>

						<div className={styles.fieldStyle}>
							<Form.Group className="from-group" controlId="formUniversity">
								<Form.Label>Instructor</Form.Label>
								<Form.Control
									name={COURSES.FORMIK.INSTRUCTOR}
									value={courseDetailsFormik.values.instructor}
									as="select"
									onChange={courseDetailsFormik.handleChange}
								>
									<option value={0} key={0}>
                    Select Instructor
									</option>
									{instructors?.map((instructor, id) => (
										<option value={instructor.id} key={id}>
											{instructor.full_name}
										</option>
									))}
								</Form.Control>
							</Form.Group>
							{courseDetailsFormik.errors.instructor &&
              courseDetailsFormik.touched.instructor ? (
									<span className={commonstyles.error}>
										{courseDetailsFormik.errors.instructor}
									</span>
								) : null}
						</div>

						{courseDetailsFormik.values.university > 0 && (
							<div className={styles.fieldStyle}>
								<Form.Label>Departments</Form.Label>

								{department?.map((res, id) => {
									return (
										<Form.Check
											key={id}
											className="from-check"
											value={res.id as number}
											type="checkbox"
											checked={
												selectedDepartments.includes(res.id as number)
													? true
													: false
											}
											onChange={handleCheckboxClick}
											label={res.name}
										></Form.Check>
									);
								})}

								{courseDetailsFormik.errors.departments &&
                courseDetailsFormik.touched.departments ? (
										<span className={commonstyles.error}>
											{courseDetailsFormik.errors.departments}
										</span>
									) : null}
							</div>
						)} */}

						{/* <Button variant="outline-secondary" className="mb-2">
                    <i className="fas fa-plus"></i> Choose More Department
                </Button> */}
						<hr />
						{/* <Button variant="outline-secondary" className="mb-2">
                    <i className="fas fa-plus"></i> Add More University
                </Button> */}
						{/* <Button className={`${styles.buttonss} mt-3`} type="submit">
							{selectedCourse.editOrAdd === INSTRUCTOR.REDUX.ADD
								? INSTRUCTOR.ADD
								: INSTRUCTOR.EDIT}
						</Button> */}
						{<>{Number(couponsDetailsFormik.values.couponType)===1&&<Form.Group className="from-group" controlId="formFirstName">
							<Form.Label>Members</Form.Label>
							<Form.Control
								disabled={loading}
								type="number"
								value={couponsDetailsFormik.values.members}									
								onChange={couponsDetailsFormik.handleChange}
								placeholder="No. of members"
								name={COUPONS.FORMIK.MEMBERS}
									
							/>
						</Form.Group>}
						{couponsDetailsFormik.errors.members &&
								couponsDetailsFormik.touched.members ? (
													  <span className={commonstyles.error}>
														  {couponsDetailsFormik.errors.members }
													  </span>
												  ) : null}
												  </>
						}
						

						{<>{Number(couponsDetailsFormik.values.couponType)===5&&<Form.Group className="from-group" controlId="formFirstName">
							<Form.Label>Text</Form.Label>
							<Form.Control
								disabled={loading}
								type="text"
								value={couponsDetailsFormik.values.text}
								placeholder="Text"
								name={COUPONS.FORMIK.TEXT}
								onChange={couponsDetailsFormik.handleChange}
									
							/>
						</Form.Group>}
						{couponsDetailsFormik.errors.text &&
								couponsDetailsFormik.touched.text ? (
													  <span className={commonstyles.error}>
														  {couponsDetailsFormik.errors.text }
													  </span>
												  ) : null}
						</>}
						{<>{(Number(couponsDetailsFormik.values.couponType)===5||Number(couponsDetailsFormik.values.couponType)===2)&&<Form.Group className="from-group" controlId="formFirstName">
							<Form.Label>Course Limit</Form.Label>
							<Form.Control
								type="number"
								disabled={loading}
								placeholder="number"
								onChange={couponsDetailsFormik.handleChange}
								value={couponsDetailsFormik.values.limit}

								name={COUPONS.FORMIK.LIMIT}
									
							/>
						</Form.Group>}
						{couponsDetailsFormik.errors.limit &&
								couponsDetailsFormik.touched.limit ? (
													  <span className={commonstyles.error}>
														  {couponsDetailsFormik.errors.limit }
													  </span>
												  ) : null}
						</>}
						
						{<>{Number(couponsDetailsFormik.values.couponType)!==6&&<Form.Group className="from-group" controlId="formFirstName">
							<Form.Label>University</Form.Label>
							
                    
							{universities?.map((university, id) => (
								<>
									<Form.Check
										key={id}
										disabled={loading}
										className="from-check"
										value={university.id as number}
										type="checkbox"
										checked={couponsDetailsFormik.values.selectedUniversities.some(
											(u) => u.university_id === university.id
									  )}
										onChange={(e)=>{console.log(couponsDetailsFormik.values.selectedUniversities, "rrr"); handleUniversityCheckboxClick(e, university.id)}}
										label={university.name}
									></Form.Check>
									{<>{couponsDetailsFormik.values.selectedUniversities.some((u) => u.university_id === university.id)&&Number(couponsDetailsFormik.values.couponType)!==0&&<Form.Group className="from-group" controlId="formFirstName">
										<Form.Label>discount</Form.Label>
										<Form.Control
											disabled={loading}
											onClick={()=>{console.log(`selectedUniversities.${couponsDetailsFormik.values.selectedUniversities.findIndex(
												(u) => u.university_id === university.id
							  )}.discount`)}}
											value={
												searchUniversity(university.id, "discount")

											}
											name={`selectedUniversities.${couponsDetailsFormik.values.selectedUniversities.findIndex(
												(u) => u.university_id === university.id
							  )}.discount`}
											type="number"
								
											placeholder="Discount"
											onChange={couponsDetailsFormik.handleChange}		
										/>
							
							 {/* {
							//@ts-ignore
							
							couponsDetailsFormik?.touched?.selectedUniversities?.length&&couponsDetailsFormik?.touched?.selectedUniversities[0]?.discount&&<ErrorMessage name={`selectedUniversities.${couponsDetailsFormik.values.selectedUniversities.findIndex(
								(u:any) => u.university_id === university.id
							  )}.discount`} component="div" className="error" />} */}

							 
										{/* 
{
	//@ts-ignore
couponsDetailsFormik.errors.selectedUniversities.length>0 &&
	couponsDetailsFormik.touched.selectedUniversities ? (
						  <span className={commonstyles.error}>
							  {couponsDetailsFormik.errors.selectedUniversities }
						  </span>
					  ) : null} */}
					   <span className={commonstyles.error}>
					
				    {
												//@ts-ignore
												couponsDetailsFormik.errors.selectedUniversities?.length&&couponsDetailsFormik.touched.selectedUniversities&&couponsDetailsFormik.errors.selectedUniversities.map((su)=>{
						
													return su.discount
							
												}) }
						  </span>
						  {/* <span className={commonstyles.error}>
						  {couponsDetailsFormik.errors.selectedUniversities &&
								couponsDetailsFormik.touched.selectedUniversities? (
													  <span className={commonstyles.error}>

														  {
														  //@ts-ignore
														  couponsDetailsFormik.errors?.selectedUniversities[0]?.discount &&selectedUniversities[0]?.discount }
													  </span>
												  ) : null}

</span> */}
						
									</Form.Group>}
						
						
									</>}
									{couponsDetailsFormik.values.selectedUniversities.some((u) => u.university_id === university.id)&&<Form.Group className="from-group" controlId="formUniversity">
										<Form.Label onClick={()=>{console.log(couponsDetailsFormik.errors.selectedUniversities)}}>Unit</Form.Label>
										<Form.Control
											disabled={loading}
								
											as="select"
											value={
												searchUniversity(university.id, "discount_type")
	
											}
											onChange={couponsDetailsFormik.handleChange}
											name={`selectedUniversities.${couponsDetailsFormik.values.selectedUniversities.findIndex(
												(u) => u.university_id === university.id
								  )}.discount_type`}		
										>
									
								
											<option value={2}>
												{"%"}
											</option>
											<option value={1}>
												{"KD"}
											</option>
										
								
										</Form.Control>
									</Form.Group>}
								</>
							))}
							
						</Form.Group>}
						{/* {couponsDetailsFormik.errors.university &&
								couponsDetailsFormik.touched.university ? (
													  <span className={commonstyles.error}>
														  {couponsDetailsFormik.errors.university }
													  </span>
												  ) : null} */}
						</>}
						{/* {(couponsDetailsFormik.values.university > 0) && (
							<div className={styles.fieldStyle}>
								<Form.Label>Departments</Form.Label>

								{department?.map((res, id) => {
									return (
										<Form.Check
											key={id}
											className="from-check"
											value={res.id as number}
											type="checkbox"
											checked={
												selectedDepartments.includes(res.id as number)
													? true
													: false
											}
											onChange={handleCheckboxClick}
											label={res.name}
										></Form.Check>
									);
								})}

								{couponsDetailsFormik.errors.departments &&
                couponsDetailsFormik.touched.departments ? (
										<span className={commonstyles.error}>
											{couponsDetailsFormik.errors.departments}
										</span>
									) : null}
							</div>
						)} */}
						{(Number(couponsDetailsFormik.values.couponType)===5||Number(couponsDetailsFormik.values.couponType)===2||Number(couponsDetailsFormik.values.couponType)===3)&& (
							<div className={styles.fieldStyle}>
								<Form.Label>Courses</Form.Label>

								{course?.map((res, id) => {
									return (
										<Form.Check
											disabled={loading}
											key={id}
											className="from-check"
											value={res.id as number}
											type="checkbox"
											checked={
												selectedCourses.includes(res.id as number)
													? true
													: false
											}
											onChange={handleCoursesCheckboxClick}
											label={res.name}
										></Form.Check>
									);
								})}

								{couponsDetailsFormik.errors.courses &&
                couponsDetailsFormik.touched.courses ? (
										<span className={commonstyles.error}>
											{couponsDetailsFormik.errors.courses}
										</span>
									) : null}
							</div>
						)}
						{(Number(couponsDetailsFormik.values.couponType)===4)&& (
							<div className={styles.fieldStyle}>
								<Form.Label>Courses</Form.Label>

								{course?.map((res, id) => {
									return (
										<Form.Check
											key={id}
											className="from-check"
											disabled={loading}
											value={res.id as number}
											type="checkbox"
											checked={selectedCourse === res.id}
											onChange={() => handleCourseCheckboxClick(res.id)}
											label={res.name}
										></Form.Check>
									);
								})}

								{couponsDetailsFormik.errors.selectedCourse &&
                couponsDetailsFormik.touched.selectedCourse ? (
										<span className={commonstyles.error}>
											{couponsDetailsFormik.errors.selectedCourse}
										</span>
									) : null}
							</div>
						)}
						{(Number(couponsDetailsFormik.values.couponType)===3)&&<Form.Group className="from-group" controlId="formFirstName">
							<Form.Label>Banner Image</Form.Label>
							<Form.Control
								type="file"
								name={"bannerImage"}
								disabled={loading}					
								onChange={handleImageUpload}
								placeholder="No. of members"
								
									
							/>
						</Form.Group>}
						{(Number(couponsDetailsFormik.values.couponType)===3)&&<Form.Group className="from-group" controlId="formFirstName">
							<Form.Label>Banner Gradient From</Form.Label>
							<Form.Control
								onChange={couponsDetailsFormik.handleChange}
								name={"gradientFrom"}
								disabled={loading}
								
								as="select">
									
								
								<option value={"#00FF00"}>
									{"Green"}
								</option>
								<option value={"#FF0000"}>
									{"red"}
								</option>
								<option value={"#0000FF"}>
									{"Blue"}
								</option>
										
								
							</Form.Control>
						</Form.Group>}
						{(Number(couponsDetailsFormik.values.couponType)===3)&&<Form.Group className="from-group" controlId="formFirstName">
							<Form.Label>Banner Gradient To</Form.Label>
							<Form.Control
								name="gradientTo"
								disabled={loading}
								onChange={couponsDetailsFormik.handleChange}
								as="select"
							>
								<option value={"#00FF00"}>
									{"Green"}
								</option>
								<option value={"#FF0000"}>
									{"red"}
								</option>
								<option value={"#0000FF"}>
									{"Blue"}
								</option>

							</Form.Control>
						</Form.Group>}
						{Number(couponsDetailsFormik.values.couponType)===6&&<>
							<Form.Group className="from-group" controlId="formFirstName">
								<Form.Label>Discount</Form.Label>
								<Form.Control
									disabled={loading}
									type="number"
									value={couponsDetailsFormik.values.discount_amount}									
									onChange={couponsDetailsFormik.handleChange}
									placeholder="Discount"
									name={COUPONS.FORMIK.DISCOUNT_AMOUNT}
									
								/>
							</Form.Group>
							{couponsDetailsFormik.errors.discount_amount &&
								couponsDetailsFormik.touched.discount_amount ? (
													  <span className={commonstyles.error}>
														  {couponsDetailsFormik.errors.discount_amount }
													  </span>
												  ) : null}


												  <Form.Group className="from-group" controlId="formUniversity">
								<Form.Label onClick={()=>{console.log(couponsDetailsFormik.errors.selectedUniversities)}}>Unit</Form.Label>
								<Form.Control
									disabled={loading}
								
									as="select"
									value={
										couponsDetailsFormik.values.discount_type
	
									}
									onChange={couponsDetailsFormik.handleChange}
									name={COUPONS.FORMIK.DISCOUNT_TYPE}		
								>
									
								
									<option value={2}>
										{"%"}
									</option>
									<option value={1}>
										{"KD"}
									</option>
										
								
								</Form.Control>
							</Form.Group>
						</>}
						

					</Form>
					{(Number(couponsDetailsFormik.values.couponType)===5||Number(couponsDetailsFormik.values.couponType)===2||Number(couponsDetailsFormik.values.couponType)===3)&&<div className={styles.CouponCode} style={{color: couponsDetailsFormik.values.textColor, backgroundColor: couponsDetailsFormik.values.backgroundColor}}>
						<div className={styles.outerCoupon} style={{color: couponsDetailsFormik.values.textColor, backgroundColor: couponsDetailsFormik.values.backgroundColor}}>
							<div className={styles.leftCoupon}>
								<h6 style={{color: couponsDetailsFormik.values.textColor}}>Coupon Voucher</h6>
								<h4 style={{color: couponsDetailsFormik.values.textColor}}>{`${"x %"} Off`}</h4>
							</div>
							<div className={styles.rightCoupon}>
								<h4 style={{color: couponsDetailsFormik.values.textColor}}>{`${couponsDetailsFormik.values.text}`}</h4>
								<h5 style={{color: couponsDetailsFormik.values.textColor}}>{`${"X %"} Off`} <span>on {couponsDetailsFormik.values.limit} courses</span></h5>
								<h6 style={{color: couponsDetailsFormik.values.textColor}}>Valid until of {moment(couponsDetailsFormik.values.date).format("MMM DD, YYYY")}</h6>
								<span style={{color: couponsDetailsFormik.values.textColor, backgroundColor: couponsDetailsFormik.values.buttonColor}} className={styles.tag}>{`${couponsDetailsFormik.values.code}`}</span>
								<div className={styles.copyBtn}>
									<Link style={{color: couponsDetailsFormik.values.textColor, backgroundColor: couponsDetailsFormik.values.buttonColor}} to="#"><img src={cpy} alt=""/> Copy Code</Link>
									<Link style={{color: couponsDetailsFormik.values.textColor, backgroundColor: couponsDetailsFormik.values.buttonColor}} to="#"><img src={shr} alt=""/> Share</Link>
								</div>
							</div>
						</div>
					</div>}
					{	(Number(couponsDetailsFormik.values.couponType)===1||Number(couponsDetailsFormik.values.couponType)===4)&&<div style={{color: couponsDetailsFormik.values.textColor, backgroundColor: couponsDetailsFormik.values.backgroundColor}} className={styles.CouponCode}>
						<div onClick={()=>{console.log(couponsDetailsFormik.errors)}} style={{color: couponsDetailsFormik.values.textColor}}  className={styles.outerCoupon}>
							<div className={styles.leftCoupon}>
								<img style={{backgroundColor: couponsDetailsFormik.values.buttonColor}} src={users} alt=""/>
								<span style={{color: couponsDetailsFormik.values.textColor}}>{`${"X %"}` }<br/> <b>OFF</b></span>
							</div>
							<div style={{color: couponsDetailsFormik.values.textColor}} className={styles.rightCoupon}>
								{Number(couponsDetailsFormik.values.couponType)===1&&<h4 style={{color: couponsDetailsFormik.values.textColor}}>{`${couponsDetailsFormik.values.members} Members`} </h4>}
								<h5 style={{color: couponsDetailsFormik.values.textColor}}>{`${"X %"} OFF` }</h5>
								<h6 style={{color: couponsDetailsFormik.values.textColor}}>{`Valid until of ${moment(couponsDetailsFormik.values.date).format("MMM DD, YYYY")}`}</h6>
								<span style={{color: couponsDetailsFormik.values.textColor, backgroundColor: couponsDetailsFormik.values.buttonColor}} className={styles.tag}>{`${couponsDetailsFormik.values.code}`}</span>
								<div className={styles.copyBtn}>
									<Link style={{color: couponsDetailsFormik.values.textColor, backgroundColor: couponsDetailsFormik.values.buttonColor}} to="#"><img src={cpy} alt=""/> Copy Code</Link>
									<Link style={{color: couponsDetailsFormik.values.textColor, backgroundColor: couponsDetailsFormik.values.buttonColor}} to="#"><img src={shr} alt=""/> Share</Link>
								</div>
							</div>
						</div>
					</div>}
					<Button onClick={()=>{couponsDetailsFormik.submitForm();
					}
						
					} className={`${styles.buttonss} mt-3`} type="submit">
						{selectedCoupon.editOrAdd === INSTRUCTOR.REDUX.ADD
							? "Add"
							: "Edit"}
					</Button>
				</Modal.Body>
			</div>
		</Modal>
	);
};

export default AddorEdit;
