export const ROUTE_CONSTANTS = {
	LOGIN: "/login",
	SIGNUP: "/signup",
	INSTRUCTOR:"/instructors",
	STUDENTS:"/students",
	COURSE_INSTALLMENT:"/students/course/instalments",
	COURSES:"/courses",
	UNIVERSITIES:"/universities",
	PROFILE:"/profile",
	COUPONS:"/coupons",
	INSTRUCTORS_COURSES:"/instructors/courses",
	INSTRUCTORS_COURSES_STUDENTLIST:"/instructors/courses/studentlist",
	INSTRUCTORS_CHAPTERS:"/instructors/courses/chapter",
	INSTRUCTOR_CHAPTERINFO:"/instructors/courses/chapter/chapterInfo",
	INVOICE:"/invoice"
};

