import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface Department {
	id: Number,
	editOrAdd: String
}

const initialState: Department = {
	id: 0,
	editOrAdd: "Add"
}

export const departmentSlice = createSlice({
	name: "department",
	initialState,
	reducers: {
		departmentModal: (state, action: PayloadAction<Department>) => {
			state.editOrAdd = action.payload.editOrAdd
			state.id = action.payload.id

		},
	},
});

export const { departmentModal } = departmentSlice.actions;
export const departmentSelector = (state: RootState) => state.departmentReducer;

export default departmentSlice.reducer;