import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface Chapter {
    id:Number,
	editOrAdd?: String
}

const initialState: Chapter = 
	{
		id:0,
		editOrAdd: "Add"
	}


export const chapterSlice = createSlice({
	name: "chapter",
	initialState,
	reducers: {
		chapterModal: (state, action: PayloadAction<Chapter>) => {
			
			state.id=action.payload.id
			state.editOrAdd = action.payload.editOrAdd

		},
	},
});

export const { chapterModal } = chapterSlice.actions;
export const chapterSelector = (state: RootState) => state.chapterReducer;

export default chapterSlice.reducer;