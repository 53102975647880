import * as Yup from "yup";
// import { YUP } from "../../../utils/validations";
// import { COURSES } from "../../../utils/courses";

const InvoiceDetailsSchema = Yup.object().shape({
	name: Yup.string().required(),
	email: Yup.string().email().required(),
	mobile: Yup.number().positive().required(),
	expiry: Yup.string().required(),
	amount: Yup.number().positive().required()

});


export { InvoiceDetailsSchema};